import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaTimes, FaUserFriends, FaSave, FaTimesCircle } from 'react-icons/fa';
import 'react-datepicker/dist/react-datepicker.css';

import { useAuth } from '~/hooks';
import * as Yup from 'yup';

import api from '~/services/api';

import { DatePicker, Input, InputMask, Select } from '~/components/Form';
import { TableLoading } from '~/components/Table';

import { Container, Header, Controls } from '~/styles/components';
import { Content, EmployeeForm } from './styles';

const Form = () => {
  const { company } = useAuth();
  const formRef = useRef(null);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState({
    cpf: '',
    name: '',
    client_id: '',
    situation: 0,
    company_id: company.id,
    admission_date: null,
    resignation_date: null,
    attest_date: null,
    first_ctto_date: null,
    second_ctto_date: null,
  });

  const [clientsOptions, setClientsOptions] = useState([]);
  const [isResignationEnabled, setIsResignationEnabled] = useState(false);

  const loadClients = useCallback(async () => {
    if (company) {
      try {
        const response = await api.get(`/relationships`, {
          params: {
            company_id: company.id,
            selectOnly: true,
            active: true,
            type: 1,
          },
        });

        let options = [];
        if (response.data.length > 0) {
          response.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          options = response.data
            .map(item => ({
              value: item.id,
              label: item.name,
            }))
            .filter(
              option => option.value !== undefined && option.value !== null
            );
        } else {
          toast.warn('Nenhum cliente foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setClientsOptions(options);
        setLoading(false);
      } catch {
        toast.error('Falha ao buscar clientes.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [company]);

  useEffect(() => {
    loadClients();
  }, [loadClients]);

  const employeeSchema = Yup.object().shape({
    cpf: Yup.string()
      .required('CPF é obrigatório')
      .min(14, 'CPF deve ter no mínimo 14 caracteres'),
    name: Yup.string().required('Nome é obrigatório'),
    client_id: Yup.string().required('Cliente é obrigatório'),
    situation: Yup.number().required('Situação é obrigatória'),
    admission_date: Yup.date().nullable(),
    resignation_date: Yup.date().nullable(),
    attest_date: Yup.date().nullable(),
    first_ctto_date: Yup.date().nullable(),
    second_ctto_date: Yup.date().nullable(),
  });

  const handleSave = useCallback(async () => {
    try {
      const formattedEmployee = {
        ...employee,
        admission_date: employee.admission_date
          ? employee.admission_date.toISOString()
          : null,
        resignation_date: employee.resignation_date
          ? employee.resignation_date.toISOString()
          : null,
        attest_date: employee.attest_date
          ? employee.attest_date.toISOString()
          : null,
        first_ctto_date: employee.first_ctto_date
          ? employee.first_ctto_date.toISOString()
          : null,
        second_ctto_date: employee.second_ctto_date
          ? employee.second_ctto_date.toISOString()
          : null,
      };
      await employeeSchema.validate(formattedEmployee, { abortEarly: false });

      await api.post(`/employees/`, formattedEmployee);

      toast.success('Funcionário alterado com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      history.push('/employee-registration');
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
          toast.error(err.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
        formRef.current.setErrors(validationErrors);
      } else {
        toast.error('Falha ao salvar funcionário.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [employee, employeeSchema, history]);

  const handleCancel = useCallback(() => {
    history.push('/employee-registration');
  }, [history]);

  const handleChange = (e, name) => {
    const { value } = e.target;
    setEmployee(prevEmployee => ({
      ...prevEmployee,
      [name]: value,
    }));
  };

  const handleSelectChange = selectedOption => {
    setEmployee(prevEmployee => ({
      ...prevEmployee,
      client_id: selectedOption.value,
    }));
  };

  const handleSituationChange = selectedOption => {
    setEmployee(prevEmployee => ({
      ...prevEmployee,
      situation: selectedOption.value,
    }));

    if (selectedOption.value === 1) {
      setIsResignationEnabled(true);
    } else {
      setIsResignationEnabled(false);
      setEmployee(prevEmployee => ({
        ...prevEmployee,
        resignation_date: null,
      }));
    }
  };

  const formatCPF = value => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
  };

  const handleCPFChange = e => {
    const { value } = e.target;
    setEmployee(prevEmployee => ({
      ...prevEmployee,
      cpf: formatCPF(value),
    }));
  };

  const situationOptions = [
    { value: 0, label: 'Admitido' },
    { value: 1, label: 'Demitido' },
  ];

  return (
    <Container>
      <Header>
        <div>
          <FaUserFriends />
          <h1>Cadastro de Empregados</h1>
        </div>

        <Link to="/">
          <FaTimes />
        </Link>
      </Header>
      <Controls>
        <button type="button" onClick={handleSave}>
          <FaSave size={15} color="#44546a" />
          <span>Salvar</span>
        </button>
        <button type="button" onClick={handleCancel}>
          <FaTimesCircle />
          <span>Cancelar</span>
        </button>
      </Controls>
      {loading ? (
        <TableLoading />
      ) : (
        <Content className="content">
          <EmployeeForm ref={formRef} onSubmit={handleSave}>
            <h4>Funcionários</h4>
            <section>
              <Input
                name="name"
                className="name"
                label="Nome"
                defaultValue={employee.name}
                onChange={e => handleChange(e, 'name')}
              />
              <InputMask
                name="cpf"
                className="cpf"
                label="CPF"
                value={employee.cpf}
                onChange={handleCPFChange}
                mask="999.999.999-99"
                maskChar={null}
              />
              <div className="client-container">
                <Select
                  name="client_id"
                  className="client"
                  label="Cliente"
                  type="text"
                  options={clientsOptions}
                  placeholder="Selecione um cliente"
                  onChange={handleSelectChange}
                />
              </div>
              <div className="situation-container">
                <Select
                  name="situation"
                  className="situation"
                  label="Situação"
                  type="text"
                  options={situationOptions}
                  placeholder="Selecione a situação"
                  onChange={handleSituationChange}
                  value={situationOptions.find(
                    option => option.value === employee.situation
                  )}
                />
              </div>
            </section>
            <section>
              <div className="date">
                <label>Admissão</label>
                <DatePicker
                  name="admission_date"
                  selected={employee.admission_date}
                  onChange={date =>
                    setEmployee(prevEmployee => ({
                      ...prevEmployee,
                      admission_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="date">
                <label>Demissão</label>
                <DatePicker
                  name="resignation_date"
                  selected={employee.resignation_date}
                  onChange={date =>
                    setEmployee(prevEmployee => ({
                      ...prevEmployee,
                      resignation_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                  disabled={!isResignationEnabled}
                />
              </div>
              <div className="date">
                <label>Data de atestado</label>
                <DatePicker
                  name="attest_date"
                  selected={employee.attest_date}
                  onChange={date =>
                    setEmployee(prevEmployee => ({
                      ...prevEmployee,
                      attest_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="date">
                <label>Fim Etapa 1 Ctto</label>
                <DatePicker
                  name="first_ctto_date"
                  selected={employee.first_ctto_date}
                  onChange={date =>
                    setEmployee(prevEmployee => ({
                      ...prevEmployee,
                      first_ctto_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="date">
                <label>Fim Etapa 2 Ctto</label>
                <DatePicker
                  name="second_ctto_date"
                  selected={employee.second_ctto_date}
                  onChange={date =>
                    setEmployee(prevEmployee => ({
                      ...prevEmployee,
                      second_ctto_date: date,
                    }))
                  }
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </section>
          </EmployeeForm>
        </Content>
      )}
    </Container>
  );
};

export default Form;
