import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaTimes, FaClipboardList, FaClock } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import base64 from 'base-64';

import api from '~/services/api';
import history from '~/services/history';

import { useAuth } from '~/hooks';

import NewsProcessModal from '~/components/NewsProcessModal';
import ProcessTypesModal from '~/components/ProcessTypes';
import ProcessSettingsOptions from '~/components/ProcessSettingsOptions';
import ProcessPortalLinks from '~/components/ProcessPortalLinks';
import { TableLoading } from '~/components/Table';
import PermissionComponent from '~/components/PermissionComponent';
import LaborProcessForm from './ListProcess/Form';
import LaborProcessList from './ListProcess/List';

import {
  Container,
  Header,
  Content,
  ProcessTypes,
  Type,
  InProgress,
  NewProcessButton,
} from './styles';

const LaborProcess = () => {
  const { user, company } = useAuth();

  const [modelsLoading, setModelsLoading] = useState(true);
  const [newsProcessesLoading, setNewsProcessesLoading] = useState(true);
  const [processTypes, setProcessTypes] = useState([]);

  const [configModalIsOpen, setConfigModalIsOpen] = useState(false);
  const [createNewModel, setCreateNewModel] = useState(false);

  const [isOpenNewsModal, setIsOpenNewsModal] = useState(false);
  const [newsProcessData, setNewsProcessData] = useState([]);

  const loadModels = useCallback(async () => {
    if (user && company) {
      try {
        setModelsLoading(true);

        const response = await api.get(`/process-models/simple`, {
          params: {
            company_id: company.id,
            model_type: 1,
          },
        });

        const { data } = response;

        if (data.length > 0) {
          const dataFormatted = data.map(item => ({
            id: item.id,
            title: item.menu_title ? item.menu_title : item.title,
            icon: item.icon.name,
            processes: item.processesCount,
            portalLink: `https://app.diretiva1.com.br/redirect/${base64.encode(
              company.id
            )}/${base64.encode(item.id)}`,
            hasProcessesLate: item.hasProcessesLate > 0,
          }));

          dataFormatted.sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          });

          setProcessTypes(dataFormatted);
        } else {
          setProcessTypes([]);
          toast.warn('Nenhum modelo foi encontrado.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }

        setModelsLoading(false);
      } catch {
        toast.error('Falha ao buscar modelos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setModelsLoading(false);
      }
    }
  }, [company, user]);

  const loadNewsProcess = useCallback(async () => {
    if (company && user) {
      try {
        setNewsProcessesLoading(true);
        const response = await api.get('/process-models/processes/not-view', {
          params: {
            company_id: company.id,
            user_id: user.id,
            model_type: 1,
          },
        });

        setNewsProcessData(response.data);
        setNewsProcessesLoading(false);
      } catch {
        setNewsProcessesLoading(false);
        toast.error('Falha ao buscar os novos processos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [company, user]);

  useEffect(() => {
    if (!configModalIsOpen) {
      loadModels();
      loadNewsProcess();
    }
  }, [configModalIsOpen, loadModels, loadNewsProcess]);

  const handleNewModel = useCallback(() => {
    setCreateNewModel(true);
    setConfigModalIsOpen(true);
  }, []);

  const handleConfigModels = useCallback(() => {
    setCreateNewModel(false);
    setConfigModalIsOpen(true);
  }, []);

  const moveToListProcesses = useCallback((model_id, notView) => {
    if (notView) {
      localStorage.setItem(
        '@Diretiva:labor-process:filter:view',
        JSON.stringify({ label: 'Não visualizados', value: 0 })
      );
    } else {
      localStorage.setItem(
        '@Diretiva:labor-process:filter:view',
        JSON.stringify({ label: 'Todos', value: '' })
      );
    }

    history.push({
      pathname: '/labor-process',
      state: { model_id },
    });
  }, []);

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaClipboardList size={20} color="#44546a" />
            <h1>Processos Trabalhistas</h1>
          </div>
          <aside>
            <NewProcessButton onClick={() => setIsOpenNewsModal(true)}>
              <strong>NEWS</strong>
              {newsProcessData.length > 0 && <div />}
            </NewProcessButton>
            <ProcessPortalLinks models={processTypes} />
            <PermissionComponent level={9}>
              {company && (
                <ProcessSettingsOptions
                  newModel={handleNewModel}
                  configModels={handleConfigModels}
                  loadModels={loadModels}
                  company_id={company.id}
                  type={0}
                />
              )}
            </PermissionComponent>
            <Link to="/">
              <FaTimes size={20} color="#44546a" />
            </Link>
          </aside>
        </Header>

        {modelsLoading || newsProcessesLoading ? (
          <TableLoading />
        ) : (
          <Content className="content">
            <ProcessTypes>
              {processTypes.map((type, index) => (
                <Type key={index} onClick={() => moveToListProcesses(type.id)}>
                  <FontAwesomeIcon icon={type.icon} color="#44546a" />
                  <span>{type.title}</span>
                  <InProgress>
                    {type.processes > 0 ? (
                      <>
                        <FaClock
                          size={23}
                          color={type.hasProcessesLate ? '#E53935' : '#f4c306'}
                        />
                      </>
                    ) : (
                      <FaClock size={23} color="#44546a" />
                    )}
                  </InProgress>
                </Type>
              ))}
            </ProcessTypes>
          </Content>
        )}
      </Container>

      {configModalIsOpen && (
        <ProcessTypesModal
          isOpen={configModalIsOpen}
          setIsOpen={() => setConfigModalIsOpen(isOpen => !isOpen)}
          newModel={createNewModel}
          defaultType={1}
        />
      )}

      {isOpenNewsModal && (
        <NewsProcessModal
          type={1}
          isOpen={isOpenNewsModal}
          setIsOpen={() => setIsOpenNewsModal(false)}
          data={newsProcessData}
          moveTo={moveToListProcesses}
        />
      )}
    </>
  );
};

export { LaborProcess, LaborProcessList, LaborProcessForm };
