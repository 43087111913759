import React, {
  useRef,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  format,
  parseISO,
  getYear,
  getMonth,
  getDate,
  getHours,
  setMinutes,
  setHours,
} from 'date-fns';
import * as Yup from 'yup';
import { useWindowSize } from '@react-hook/window-size';
import TextareaAutosize from 'react-autosize-textarea';
import {
  FaClock,
  FaTimes,
  FaEye,
  FaPlus,
  FaSave,
  FaBroom,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaCheck,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';

import getValidationErrors from '~/util/getValidationErrors';
import schedulerLastCreation from '~/util/schedulerLastCreation';

import Modal from '~/components/Modal';
import { TableLoading, TableContainer } from '~/components/Table';
import ConfirmWindow from '~/components/ConfirmWindow';
import Loading from '~/components/Loading';
import Pagination from '~/components/Pagination';
import PermissionComponent from '~/components/PermissionComponent';

import {
  FormContainer,
  FormLoading,
  DatePicker,
  Select,
  MultiCheckboxSelect,
  Checkbox,
  TextArea,
  Input,
} from '~/components/Form';

import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  SchedulerInfo,
  Logs,
} from './styles';

const Scheduler = ({ isOpen, setIsOpen }) => {
  const { user, company, companyUsers } = useAuth();

  const [width] = useWindowSize();

  const formRef = useRef(null);
  const filterRef = useRef(null);

  const [showTable, setShowTable] = useState(true);
  const [isCreatingOrEditing, setIsCreatingOrEditing] = useState(false);
  const [schedulerLoading, setSchedulerLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [schedulerList, setSchedulerList] = useState([]);
  const [schedulerDetails, setSchedulerDetails] = useState({});
  const [schedulerDetailsIndex, setSchedulerDetailsIndex] = useState(0);
  const [totalSchedulerItems, setTotalSchedulerItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [schedulerItemId, setSchedulerItemId] = useState(null);
  const [schedulerItem, setSchedulerItem] = useState(null);

  const [selectedUser, setSelectedUser] = useState({
    value: user.id,
    label: user.short_name,
  });

  const usersOptions = useMemo(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return { value: userItem.user_id, label: userItem.short_name };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
      });

      return options;
    }

    return [];
  }, [companyUsers, user]);

  const loadScheduler = useCallback(async () => {
    if (company) {
      if (isCreatingOrEditing === false) {
        try {
          setSchedulerLoading(true);

          const response = await api.get('scheduler', {
            params: {
              company_id: company.id,
              recipient_id: selectedUser.value,
              page: currentPage,
            },
          });

          if (response.data.docs.length > 0) {
            const formattedSchedulerItems = response.data.docs.map(item => ({
              ...item,
              formatted_start_date: format(
                parseISO(item.start_date),
                "dd/MM/yyyy 'às' HH:mm"
              ),
              recipients_names:
                item.recipients.length === usersOptions.length
                  ? ['Todos os usuários']
                  : item.recipients.map(recipient => recipient.name),
              logs: item.logs.map(log => ({
                id: log.id,
                created_at: format(
                  parseISO(log.created_at),
                  "dd/MM/yyyy 'às' HH:mm "
                ),
              })),
            }));

            setSchedulerList(formattedSchedulerItems);
            setSchedulerDetails(formattedSchedulerItems[0]);
            setTotalPages(response.data.pages);
            setTotalSchedulerItems(response.data.total);
          } else {
            setSchedulerList([]);
            setSchedulerDetails({});
            setTotalPages(1);
            setTotalSchedulerItems(0);
            toast.warn('Nenhum compromisso foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }

          setSchedulerLoading(false);
        } catch {
          toast.error('Falha ao buscar compromissos.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSchedulerLoading(false);
        }
      }
    }
  }, [
    isCreatingOrEditing,
    company,
    selectedUser.value,
    usersOptions,
    currentPage,
  ]);

  useEffect(() => {
    loadScheduler();
  }, [loadScheduler]);

  useEffect(() => {
    async function loadSchedulerItem() {
      if (isCreatingOrEditing === true) {
        if (schedulerItemId) {
          const foundSchedulerItem = schedulerList.find(
            option => option.id === schedulerItemId
          );

          const { start_date, recipients } = foundSchedulerItem;

          const hour = getHours(parseISO(start_date));

          if (hour === 1) {
            foundSchedulerItem.start_hour = '01:00';
          } else {
            foundSchedulerItem.start_hour = '06:00';
          }

          foundSchedulerItem.start_date = parseISO(
            foundSchedulerItem.start_date
          );

          foundSchedulerItem.recipients = recipients.map(
            option => option.recipient_id
          );

          setSchedulerItem(foundSchedulerItem);
        } else {
          setSchedulerItem({
            private: true,
            recipients: [usersOptions[0]?.value],
          });
        }
      }
    }

    loadSchedulerItem();
  }, [isCreatingOrEditing, schedulerItemId, schedulerList, usersOptions]);

  const startHourOptions = useMemo(() => {
    return [
      {
        value: '01:00',
        label: '01:00',
      },
      {
        value: '06:00',
        label: '06:00',
      },
    ];
  }, []);

  const priorityOptions = useMemo(() => {
    const options = [
      { value: 1, label: 'Normal' },
      { value: 2, label: 'Urgente' },
    ];

    return options;
  }, []);

  const typeOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'Tarefa' },
      { value: 3, label: 'KPI' },
    ];

    return options;
  }, []);

  const deadlineTypeOptions = useMemo(() => {
    const options = [
      { value: 1, label: 'Hora(s)' },
      { value: 2, label: 'Dia(s)' },
      { value: 3, label: 'Semana(s)' },
      { value: 4, label: 'Mes(es)' },
      { value: 5, label: 'Ano(s)' },
    ];

    return options;
  }, []);

  const frequencyTypeOptions = useMemo(() => {
    const options = [
      { value: 1, label: 'Hora(s)' },
      { value: 2, label: 'Dia(s)' },
      { value: 3, label: 'Semana(s)' },
      { value: 4, label: 'Mes(es)' },
      { value: 5, label: 'Ano(s)' },
    ];

    return options;
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (company && user) {
        try {
          setSaveLoading(true);

          const schema = Yup.object().shape({
            start_date: Yup.string()
              .required('A data de início é obrigatória.')
              .nullable(),
            start_hour: Yup.string().required(
              'A hora de início é obrigatória.'
            ),
            recipients: Yup.array()
              .min(1)
              .required('Pelo menos um usuário é obrigatório.'),
            priority: Yup.string().required('A prioridade é obrigatória.'),
            type: Yup.string().required('O tipo é obrigatório.'),
            message: Yup.string().required('A mensagem é obrigatória.'),
            frequency: Yup.string().required('A frequencia é obrigatória.'),
            frequency_type: Yup.string().required('O tipo é obrigatório.'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          const startHour = data.start_hour.split(':');

          data.start_date = setMinutes(
            setHours(
              new Date(
                getYear(data.start_date),
                getMonth(data.start_date),
                getDate(data.start_date)
              ),
              startHour[0]
            ),
            startHour[1]
          );

          const schedulerData = {
            company_id: company.id,
            sender_id: user.id,
            message: data.message,
            priority: data.priority,
            type: data.type,
            start_date: data.start_date,
            deadline: data.deadline,
            deadline_type: data.deadline_type,
            frequency: data.frequency,
            frequency_type: data.frequency_type,
            private: data.recipients.length > 1 ? true : data.private,
            active: data.active,
            monitor: data.monitor,
            last_creation: schedulerLastCreation(
              data.frequency_type,
              data.frequency,
              data.start_date
            ),
          };

          if (schedulerItemId) {
            await api.put(`scheduler/${schedulerItemId}`, schedulerData);

            await api.post('scheduler/recipient', {
              recipients: data.recipients,
              scheduler_id: schedulerItemId,
            });
          } else {
            const createdScheduler = await api.post('scheduler', schedulerData);

            const { id: createdSchedulerId } = createdScheduler.data;

            await api.post('scheduler/recipient', {
              recipients: data.recipients,
              scheduler_id: createdSchedulerId,
            });
          }

          formRef.current.setErrors({});

          toast.success('Compromisso salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);
          setSchedulerItem(null);
          setIsCreatingOrEditing(false);
          setShowTable(true);
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errors = getValidationErrors(err);

            formRef.current.setErrors(errors);
          } else {
            toast.error('Falha ao salvar compromisso.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setSaveLoading(false);
        }
      }
    },
    [company, user, schedulerItemId]
  );

  const handleView = useCallback(() => {
    if (schedulerList.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, schedulerList.length]);

  const handleFilter = useCallback(
    data => {
      if (data.user === '') {
        setSelectedUser({ value: '', label: 'Todos' });
      } else {
        setSelectedUser(
          usersOptions.find(option => option.value === data.user)
        );
      }
    },
    [usersOptions]
  );

  const getDetails = useCallback(
    (id, index) => {
      const item = schedulerList.find(option => option.id === id);

      setSchedulerDetails(item);
      setSchedulerDetailsIndex(index);
      handleView();
    },
    [schedulerList, handleView]
  );

  const handlePrevItem = useCallback(() => {
    if (schedulerDetailsIndex !== 0) {
      setSchedulerDetailsIndex(schedulerDetailsIndex - 1);
      setSchedulerDetails(schedulerList[schedulerDetailsIndex - 1]);
    } else {
      setSchedulerDetailsIndex(schedulerList.length - 1);
      setSchedulerDetails(schedulerList[schedulerList.length - 1]);
    }
  }, [schedulerDetailsIndex, schedulerList]);

  const handleNextItem = useCallback(() => {
    if (schedulerDetailsIndex !== schedulerList.length - 1) {
      setSchedulerDetailsIndex(schedulerDetailsIndex + 1);
      setSchedulerDetails(schedulerList[schedulerDetailsIndex + 1]);
    } else {
      setSchedulerDetailsIndex(0);
      setSchedulerDetails(schedulerList[0]);
    }
  }, [schedulerDetailsIndex, schedulerList]);

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },
    [totalPages]
  );

  const handleNew = useCallback(() => {
    setSchedulerItemId(null);
    setIsCreatingOrEditing(true);
  }, []);

  const userAuth = useAuth();

  const handleEdit = useCallback(() => {
    if (
      schedulerDetails.sender.id === userAuth.user.id ||
      userAuth.companyUser.level === 9
    ) {
      setSchedulerItemId(schedulerDetails.id);
      setIsCreatingOrEditing(true);
    } else {
      toast.warn(
        'Somente o criador do compromisso ou nível administrador pode editá-lo.',
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
      );
    }
  }, [schedulerDetails, userAuth]);

  const handleDelete = useCallback(async () => {
    try {
      setDeleteLoading(true);

      await api.delete(`scheduler/${schedulerDetails.id}`);

      setDeleteLoading(false);
      handleView();
      loadScheduler();

      toast.success('Agendador deletado com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch {
      toast.error('Falha ao deletar agendador.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setDeleteLoading(false);
    }
  }, [schedulerDetails, handleView, loadScheduler]);

  const confirmDelete = useCallback(() => {
    if (schedulerDetails.sender.id !== user.id) {
      toast.warn('Somente o criador do compromisso pode deletá-lo.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return <ConfirmWindow onClick={handleDelete} onClose={onClose} />;
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  }, [handleDelete, schedulerDetails, user.id]);

  const resetForm = useCallback(() => {
    formRef.current.reset();
    formRef.current.setErrors({});
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    setSchedulerItemId(null);
    setSchedulerItem(null);
    setIsCreatingOrEditing(false);
    setShowTable(true);
  }, []);

  const confirmClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={handleClose} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [handleClose]);

  const handleChangeType = useCallback(
    selectedOption => {
      const { value } = selectedOption;

      const newSchedulerItem = { ...schedulerItem };

      if (value === 3) {
        newSchedulerItem.monitor = true;
      } else {
        newSchedulerItem.monitor = false;
      }

      setSchedulerItem(newSchedulerItem);
    },
    [schedulerItem]
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaClock size={20} color="#44546a" />
            <h1>Agendador</h1>
          </div>
          {!isCreatingOrEditing && (
            <aside>
              <button type="button" onClick={setIsOpen}>
                <FaTimes size={20} color="#44546a" />
              </button>
            </aside>
          )}
        </Header>
        <Controls>
          {!isCreatingOrEditing ? (
            <>
              <button type="button" onClick={handleView}>
                <FaEye size={15} color="#44546a" />
                <span>Visualização</span>
              </button>
              <button type="button" onClick={handleNew}>
                <FaPlus size={15} color="#44546a" />
                <span>Novo</span>
              </button>
              {!showTable && (
                <>
                  <button type="button" onClick={handleEdit}>
                    <FaEdit size={15} color="#44546a" />
                    <span>Editar</span>
                  </button>
                  <button type="button" onClick={confirmDelete}>
                    <FaTrash size={15} color="#44546a" />
                    <span>Excluir</span>
                  </button>
                  <div>
                    <button type="button" onClick={handlePrevItem}>
                      <FaChevronLeft size={15} color="#44546a" />
                    </button>
                    {totalSchedulerItems > 25 ? (
                      <span>{schedulerDetailsIndex + 1} de 25</span>
                    ) : (
                      <span>
                        {schedulerDetailsIndex + 1} de {totalSchedulerItems}
                      </span>
                    )}
                    <button type="button" onClick={handleNextItem}>
                      <FaChevronRight size={15} color="#44546a" />
                    </button>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <button type="submit" form="form">
                <FaSave size={15} color="#44546a" />
                <span>Salvar</span>
              </button>
              <button type="button" onClick={confirmResetForm}>
                <FaBroom size={15} color="#44546a" />
                <span>Limpar</span>
              </button>
              <button type="button" onClick={confirmClose}>
                <FaTimes size={15} color="#44546a" />
                <span>Fechar</span>
              </button>
            </>
          )}
        </Controls>
        <PermissionComponent level={2}>
          {showTable && !isCreatingOrEditing && (
            <Filter ref={filterRef} onSubmit={handleFilter}>
              <Select
                label="Usuário"
                name="user"
                className="user"
                options={[{ value: '', label: 'Todos' }, ...usersOptions]}
                defaultValue={selectedUser}
              />

              <button type="submit">
                <FaSearch />
              </button>
            </Filter>
          )}
        </PermissionComponent>
        {!isCreatingOrEditing ? (
          <>
            {schedulerLoading || !user || !company || !companyUsers ? (
              <TableLoading />
            ) : (
              <Content className="content">
                {showTable ? (
                  <TableContainer>
                    <thead>
                      <tr>
                        <th className="active">Ativo</th>
                        <th className="message">Mensagem</th>
                        <th className="recipient">Destinatário</th>
                        <th className="frequency">Agendar a cada</th>
                        <th className="frequency_type">Periodicidade</th>
                      </tr>
                    </thead>
                    <tbody>
                      {schedulerList &&
                        schedulerList.map((scheduler, index) => (
                          <tr
                            key={String(scheduler.id)}
                            className="hover"
                            onClick={() => getDetails(scheduler.id, index)}
                          >
                            {scheduler.active ? (
                              <td className="active">
                                <FaCheck size={16} color="#44546A" />{' '}
                              </td>
                            ) : (
                              <td className="active" />
                            )}
                            <td className="message">{scheduler.message}</td>
                            {scheduler.recipients_names.length === 1 ? (
                              <td className="recipient">
                                {scheduler.recipients_names[0]}
                              </td>
                            ) : (
                              <td className="recipient">Vários</td>
                            )}
                            <td className="frequency">{scheduler.frequency}</td>
                            <td className="frequency_type">
                              {scheduler.frequency_type_label}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </TableContainer>
                ) : (
                  <DetailsContainer>
                    <SchedulerInfo>
                      <h4>AGENDADOR</h4>
                      <section>
                        <div className="start_date">
                          <label>Data de início</label>
                          <input
                            name="start_date"
                            value={schedulerDetails.formatted_start_date}
                            readOnly
                          />
                        </div>

                        <div className="priority">
                          <label>Prioridade</label>
                          <input
                            name="priority"
                            value={schedulerDetails.priority_label}
                            readOnly
                          />
                        </div>
                        <div className="type">
                          <label>Tipo</label>
                          <input
                            name="type"
                            value={schedulerDetails.type_label}
                            readOnly
                          />
                        </div>
                        <div className="private checkbox">
                          <label>Privado</label>
                          <input
                            name="private"
                            checked={schedulerDetails.private}
                            type="checkbox"
                            disabled
                          />
                        </div>
                        <div className="active checkbox">
                          <label>Ativo</label>
                          <input
                            name="active"
                            checked={schedulerDetails.active}
                            type="checkbox"
                            disabled
                          />
                        </div>
                      </section>
                      <section>
                        <div className="recipients_details">
                          <label>Na agenda de</label>
                          <div className="content">
                            {schedulerDetails.recipients_names.map(
                              recipient => (
                                <span key={recipient}>{recipient}</span>
                              )
                            )}
                          </div>
                        </div>
                      </section>
                      <section>
                        <div className="message">
                          <label>Mensagem</label>
                          <TextareaAutosize
                            name="message"
                            value={schedulerDetails.message}
                            readOnly
                          />
                        </div>
                      </section>
                      <section>
                        <div className="deadline">
                          <label>Prazo</label>
                          <input
                            name="deadline"
                            value={schedulerDetails.deadline}
                            readOnly
                          />
                        </div>
                        <div className="deadline_type">
                          <input
                            name="deadline_type"
                            value={schedulerDetails.deadline_type_label}
                            readOnly
                          />
                        </div>
                        <div className="frequency">
                          <label>Agendar a cada</label>
                          <input
                            name="frequency"
                            value={schedulerDetails.frequency}
                            readOnly
                          />
                        </div>
                        <div className="frequency_type">
                          <input
                            name="frequency_type"
                            value={schedulerDetails.frequency_type_label}
                            readOnly
                          />
                        </div>
                      </section>
                      <section>
                        <div className="sender">
                          <label>Criado por</label>
                          <input
                            name="sender"
                            value={schedulerDetails.sender.name}
                            readOnly
                          />
                        </div>
                      </section>
                    </SchedulerInfo>
                    <Logs>
                      <h4>EXECUÇÕES</h4>
                      {schedulerDetails.logs.length > 0 &&
                        schedulerDetails.logs.map(log => (
                          <section key={String(log.id)}>
                            <div className="log">
                              <input
                                name="sender"
                                value={log.created_at}
                                readOnly
                              />
                            </div>
                          </section>
                        ))}
                    </Logs>
                  </DetailsContainer>
                )}
              </Content>
            )}
          </>
        ) : (
          <>
            {(schedulerLoading || !user || !company || !companyUsers) && (
              <FormLoading className="loading" />
            )}
            {schedulerItem !== null && (
              <Content className="content">
                <FormContainer
                  id="form"
                  ref={formRef}
                  onSubmit={handleSubmit}
                  loading={schedulerLoading ? 1 : 0}
                  initialData={schedulerItem}
                >
                  <SchedulerInfo>
                    <h4>AGENDADOR</h4>
                    <section>
                      {schedulerItemId ? (
                        <DatePicker
                          name="start_date"
                          className="start_date"
                          label="Data de início"
                          minDate={new Date()}
                        />
                      ) : (
                        <DatePicker
                          name="start_date"
                          className="start_date"
                          label="Data de início"
                        />
                      )}
                      <Select
                        name="start_hour"
                        className="start_hour"
                        label="Hora de início"
                        options={startHourOptions}
                        placeholder=""
                      />
                      <Select
                        name="priority"
                        className="priority"
                        label="Prioridade"
                        options={priorityOptions}
                        placeholder=""
                      />
                      <Select
                        name="type"
                        className="type"
                        label="Tipo"
                        options={typeOptions}
                        placeholder=""
                        onChange={e => {
                          handleChangeType(e);
                        }}
                      />
                      <Checkbox
                        id="private"
                        name="private"
                        className="private"
                        label="Privado"
                      />
                      {schedulerItemId && (
                        <Checkbox
                          id="active"
                          name="active"
                          className="active"
                          label="Ativo"
                        />
                      )}
                    </section>
                    <section>
                      <MultiCheckboxSelect
                        name="recipients"
                        className="recipients"
                        label="Na agenda de"
                        options={usersOptions}
                        placeholder=""
                        isMulti
                      />
                    </section>
                    <section>
                      <TextArea
                        name="message"
                        className="message"
                        label="Mensagem"
                      />
                    </section>
                    <section>
                      <Input
                        name="deadline"
                        className="deadline"
                        label="Prazo"
                        placeholder="Quantidade"
                        type="number"
                      />
                      <Select
                        name="deadline_type"
                        className="deadline_type"
                        options={deadlineTypeOptions}
                        placeholder="Tipo"
                      />
                      <Input
                        name="frequency"
                        className="frequency"
                        label="Agendar a cada"
                        placeholder="Quantidade"
                        type="number"
                      />
                      <Select
                        name="frequency_type"
                        className="frequency_type"
                        options={frequencyTypeOptions}
                        placeholder="Tipo"
                      />
                      <Checkbox
                        id="monitor"
                        name="monitor"
                        className="monitor"
                        label="Monitorar conclusão"
                      />
                    </section>
                  </SchedulerInfo>
                </FormContainer>
              </Content>
            )}
          </>
        )}
        {!isCreatingOrEditing && (
          <Pagination
            width={width}
            loading={schedulerLoading ? 1 : 0}
            currentPage={currentPage}
            pages={totalPages}
            totalDocs={totalSchedulerItems}
            handlePage={handlePage}
          />
        )}
      </Container>
      {(saveLoading || deleteLoading) && <Loading />}
    </Modal>
  );
};

export default Scheduler;

Scheduler.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
