import styled from 'styled-components';
import { FormContainer } from '~/components/Form';

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const EmployeeForm = styled(FormContainer)`
  display: flex;
  flex-direction: column;
  padding: 10px;
  visibility: ${props => (props.loading ? 'hidden' : 'visible')};

  h4 {
    margin-bottom: 16px;
    color: #44546a;
  }

  section {
    margin-right: 16px;
    color: ${({ theme }) => theme.colors.grey};

    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    & + section {
      margin-top: 16px;
    }

    > div {
      margin-right: 16px;
      margin-bottom: 16px;
      flex: 1;
      min-width: 200px;
    }
  }
`;
