import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { format, parseISO, isToday, startOfDay } from 'date-fns';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import base64 from 'base-64';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';
import { produce } from 'immer';
import TextareaAutosize from 'react-autosize-textarea';
import {
  FaCalendarAlt,
  FaClock,
  FaTimes,
  FaEye,
  FaPlus,
  FaEdit,
  FaTrash,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaEraser,
  FaCheck,
  FaSort,
  FaSortUp,
  FaSortDown,
  FaLock,
  FaLockOpen,
  FaExclamationTriangle,
  FaDownload,
  FaInfoCircle,
  FaPencilAlt,
  FaCog,
} from 'react-icons/fa';
import fileDownload from 'js-file-download';

import { addDays } from 'date-fns/esm';
import { Form } from '@unform/web';
import {
  Select,
  Input,
  DatePicker,
  SelectWithFilterActiveCheckbox,
  Checkbox,
} from '~/components/Form';
import { TableLoading, TableContainer } from '~/components/Table';
import Pagination from '~/components/Pagination';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import ConcludePhaseModal from '~/pages/Process/ListProcess/List/ConcludePhaseModal';
import ShowPopModal from '~/components/Header/SearchPop/ShowPopModal';
import OptionWindow from '~/components/OptionWindow';
import { SendFeedback } from '~/components/SendFeedback';
import base64ToBlob from '~/util/converterBase64ToBLob';
import PopUp from '~/components/Popup';
import {
  Container,
  Header,
  Controls,
  Filter,
  Content,
  DetailsContainer,
  AppointmentInfo,
  DateContainer,
  Feedback,
  FeedbackItem,
  TextAreaAsPlainText,
} from './styles';
import Scheduler from '../Scheduler';

const formatDescription = appointment => {
  if (appointment.type !== 5) {
    try {
      const parsed_description = JSON.parse(appointment.description);

      let formatted_description = `${parsed_description.title} \n`;

      const { max_document_name_length } = parsed_description;

      if (
        Object.entries(parsed_description.data.not_yet_overdue_documents)
          .length > 0
      ) {
        formatted_description += '\n\tDocumentos prestes a vencer: \n\n';

        Object.entries(
          parsed_description.data.not_yet_overdue_documents
        ).forEach((e, index) => {
          formatted_description +=
            index === 0 ? `\t\t${e[0]}: \n\n` : `\n\t\t${e[0]}: \n\n`;
          e[1].forEach(document => {
            const document_name_length = document.document_name.length.toString();
            const number_of_spaces =
              max_document_name_length - document_name_length + 4;
            const spaces = ' '.repeat(number_of_spaces);

            formatted_description += `\t\t\t- ${document.document_name +
              spaces}Vencimento: ${document.formatted_due_date}\n`;
          });
        });
      }

      if (
        Object.entries(parsed_description.data.overdue_documents).length > 0
      ) {
        formatted_description += '\n\tDocumentos vencidos: \n\n';

        Object.entries(parsed_description.data.overdue_documents).forEach(
          (e, index) => {
            formatted_description +=
              index === 0 ? `\t\t${e[0]}: \n\n` : `\n\t\t${e[0]}: \n\n`;
            e[1].forEach(document => {
              const document_name_length = document.document_name.length.toString();
              const number_of_spaces =
                max_document_name_length - document_name_length + 4;
              const spaces = ' '.repeat(number_of_spaces);

              formatted_description += `\t\t\t- ${document.document_name +
                spaces}Vencimento: ${document.formatted_due_date}\n`;
            });
          }
        );
      }

      return formatted_description;
    } catch (e) {
      return appointment.description;
    }
  }

  return `Solicitação de suporte - ${appointment.sender.name}\n\n${appointment.description}`;
};

const isJsonDescription = description => {
  try {
    JSON.parse(description);

    return true;
  } catch (e) {
    return false;
  }
};

const List = ({ match }) => {
  const { user, company, companyUser, companyUsers } = useAuth();

  const { id: matchParamsId } = match.params;

  const filterRef = useRef(null);
  const feedbackRef = useRef(null);

  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteFeedbackLoading, setDeleteFeedbackLoading] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [uploadFile, setUploadFile] = useState([]);
  const [toggleScheduler, setToggleScheduler] = useState(false);

  const [usersOptions, setUsersOptions] = useState([]);
  const [filterUser, setFilterUser] = useState(null);

  const [appointments, setAppointments] = useState([]);
  const [appointment, setAppointment] = useState(null);
  const [appointmentIndex, setAppointmentIndex] = useState(0);
  const [totalAppointments, setTotalAppointments] = useState(0);

  const [notification, setNotification] = useState(true);

  const [concludePhaseModalIsOpen, setConcludePhaseModalIsOpen] = useState(
    false
  );
  const functionToConcludeModal = useRef(null);
  const infoToConcludeModal = useRef(null);

  const [popLoading, setPopLoading] = useState(false);
  const [popModalIsOpen, setPopModalIsOpen] = useState(false);
  const popForModal = useRef(null);

  const [appointmentUpdate, setAppointmentUpdate] = useState(null);
  const priorityOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'Normal' },
      { value: 1, label: 'Urgente' },
    ];

    return options;
  }, []);

  const [descriptionSearch, setDescriptionSearch] = useState(() => {
    const descriptionStorage = localStorage.getItem(
      '@Diretiva:schedule:filter:description'
    );

    if (descriptionStorage) {
      return descriptionStorage;
    }

    return '';
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [startPeriod, setStartPeriod] = useState(() => {
    const startPeriodStorage = localStorage.getItem(
      '@Diretiva:schedule:filter:start'
    );

    if (startPeriodStorage && startPeriodStorage !== 'null') {
      return new Date(startPeriodStorage);
    }

    return null;
  });
  const [startPeriodForDatePicker, setStartPeriodForDatePicker] = useState(
    () => {
      const startPeriodStorage = localStorage.getItem(
        '@Diretiva:schedule:filter:start'
      );

      if (startPeriodStorage && startPeriodStorage !== 'null') {
        return new Date(startPeriodStorage);
      }

      return null;
    }
  );
  const [endPeriod, setEndPeriod] = useState(() => {
    const endPeriodStorage = localStorage.getItem(
      '@Diretiva:schedule:filter:end'
    );

    if (endPeriodStorage && endPeriodStorage !== 'null') {
      return new Date(endPeriodStorage);
    }

    return null;
  });
  const [endPeriodForDatePicker, setEndPeriodForDatePicker] = useState(() => {
    const endPeriodStorage = localStorage.getItem(
      '@Diretiva:schedule:filter:end'
    );

    if (endPeriodStorage && endPeriodStorage !== 'null') {
      return new Date(endPeriodStorage);
    }

    return null;
  });

  const [startDeadline, setStartDeadline] = useState(() => {
    const startDeadlineStorage = localStorage.getItem(
      '@Diretiva:schedule:filter:deadline:start'
    );

    if (startDeadlineStorage && startDeadlineStorage !== 'null') {
      return new Date(startDeadlineStorage);
    }

    return null;
  });
  const [startDeadlineForDatePicker, setStartDeadlineForDatePicker] = useState(
    () => {
      const startDeadlineStorage = localStorage.getItem(
        '@Diretiva:schedule:filter:deadline:start'
      );

      if (startDeadlineStorage && startDeadlineStorage !== 'null') {
        return new Date(startDeadlineStorage);
      }

      return null;
    }
  );
  const [endDeadline, setEndDeadline] = useState(() => {
    const endDeadlineStorage = localStorage.getItem(
      '@Diretiva:schedule:filter:deadline:end'
    );

    if (endDeadlineStorage && endDeadlineStorage !== 'null') {
      return new Date(endDeadlineStorage);
    }

    return null;
  });
  const [endDeadlineForDatePicker, setEndDeadlineForDatePicker] = useState(
    () => {
      const endDeadlineStorage = localStorage.getItem(
        '@Diretiva:schedule:filter:deadline:end'
      );

      if (endDeadlineStorage && endDeadlineStorage !== 'null') {
        return new Date(endDeadlineStorage);
      }

      return null;
    }
  );

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
    if (event.target.checked) {
      setStartDeadline(null);
      setStartDeadlineForDatePicker(null);
      setEndDeadline(new Date());
      setEndDeadlineForDatePicker(new Date());
    } else {
      setStartDeadline(null);
      setStartDeadlineForDatePicker(null);
      setEndDeadline(null);
      setEndDeadlineForDatePicker(null);
    }
  };

  const [sortConfig, setSortConfig] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedConclusion, setSelectedConclusion] = useState(() => {
    const conclusionStorage = localStorage.getItem(
      '@Diretiva:schedule:filter:conclusion'
    );

    if (conclusionStorage) {
      return JSON.parse(conclusionStorage);
    }

    return {
      value: 0,
      label: 'Não concluído',
    };
  });

  const loadSchedule = useCallback(async () => {
    if (company && selectedUser) {
      try {
        if (matchParamsId) {
          setShowTable(false);
          setAppointmentsLoading(true);

          const response = await api.get(
            `schedule/${base64.decode(matchParamsId)}`
          );

          const { data } = response;
          setAppointmentUpdate(data);

          const { start_date, deadline_date, conclusion_date } = data;

          data.is_json_description = isJsonDescription(data.description);
          data.description = formatDescription(data);
          data.deadline = deadline_date ? parseISO(deadline_date) : null;
          data.start_date = format(parseISO(start_date), 'dd/MM/yyyy');
          data.deadline_date = deadline_date
            ? format(parseISO(deadline_date), 'dd/MM/yyyy')
            : null;
          data.conclusion_date = conclusion_date
            ? format(parseISO(conclusion_date), "dd/MM/yyyy 'às' HH:mm")
            : null;
          data.start_hour = format(parseISO(start_date), 'HH:mm');
          data.deadline_hour = deadline_date
            ? format(parseISO(deadline_date), 'HH:mm')
            : null;
          const feedbackFormatted = data.feedback.map(feedbackItem => ({
            ...feedbackItem,
            feedback_date_formatted: feedbackItem.feedback_date
              ? format(
                  parseISO(feedbackItem.feedback_date),
                  "dd/MM/yyyy 'às' HH:mm"
                )
              : null,
          }));

          data.feedback = feedbackFormatted;

          setAppointment(data);
        } else {
          setShowTable(true);
          setAppointmentsLoading(true);

          const end_deadline =
            endDeadline != null ? new Date(endDeadline) : null;
          if (end_deadline != null) {
            end_deadline.setHours(0, 0, 0, 0);
          }

          const start_deadline =
            startDeadline != null ? new Date(startDeadline) : null;
          if (start_deadline != null) {
            start_deadline.setHours(0, 0, 0, 0);
          }

          const response = await api.get('schedule', {
            params: {
              company_id: company.id,
              page: currentPage,
              description: descriptionSearch,
              user: selectedUser.value,
              start_period: startPeriod,
              end_period: endPeriod,
              start_deadline,
              end_deadline,
              done: selectedConclusion.value,
              key: sortConfig.key,
              direction: sortConfig.direction,
            },
          });

          if (response.data.docs.length > 0) {
            const data = response.data.docs.map(item => {
              const deadline_date = item.deadline_date
                ? new Date(item.deadline_date)
                : null;

              const deadlineDateObj = new Date(deadline_date);
              const deadline = deadline_date
                ? `${String(deadlineDateObj.getUTCHours()).padStart(
                    2,
                    '0'
                  )}:${String(deadlineDateObj.getUTCMinutes()).padStart(
                    2,
                    '0'
                  )}`
                : null;

              return {
                ...item,
                is_json_description: isJsonDescription(item.description),
                description: formatDescription(item),
                start_date: format(new Date(item.start_date), 'dd/MM/yyyy'),
                deadline: deadline_date,
                deadline_date: deadline_date
                  ? format(
                      new Date(
                        deadline_date.getUTCFullYear(),
                        deadline_date.getUTCMonth(),
                        deadline_date.getUTCDate()
                      ),
                      'dd/MM/yyyy'
                    )
                  : null,
                conclusion_date: item.conclusion_date
                  ? format(
                      new Date(item.conclusion_date),
                      "dd/MM/yyyy 'às' HH:mm "
                    )
                  : null,
                start_hour: format(new Date(item.start_date), 'HH:mm'),
                deadline_hour: deadline_date ? deadline : null,
                feedback: item.feedback.map(feedbackItem => ({
                  ...feedbackItem,
                  feedback_date_formatted: feedbackItem.feedback_date
                    ? format(
                        new Date(feedbackItem.feedback_date),
                        "dd/MM/yyyy 'às' HH:mm"
                      )
                    : null,
                })),
              };
            });

            setAppointments(data);
            /// ///////////////////////
            if (!matchParamsId) {
              setAppointment(data[0]);
            }
            setTotalPages(response.data.pages);
            setTotalAppointments(response.data.total);
          } else {
            setAppointments([]);
            setAppointment([]);
            setTotalPages(1);
            setTotalAppointments(0);
            toast.warn('Nenhum compromisso foi encontrado.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        }

        setAppointmentsLoading(false);
      } catch (err) {
        toast.error('Falha ao buscar compromissos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setAppointmentsLoading(false);
      }
    }
  }, [
    company,
    currentPage,
    descriptionSearch,
    matchParamsId,
    selectedConclusion.value,
    sortConfig,
    selectedUser,
    startPeriod,
    endPeriod,
    startDeadline,
    endDeadline,
  ]);

  useEffect(() => {
    loadSchedule();
  }, [loadSchedule]);

  useEffect(() => {
    if (!showTable && !appointmentsLoading) {
      const element = document.getElementById('feedback_message');

      if (element) {
        element.addEventListener('keydown', event => {
          if (event.ctrlKey && event.key === 'Enter') {
            feedbackRef.current.submitForm();
          }
        });
      }
    }
  }, [showTable, appointmentsLoading]);

  useEffect(() => {
    if (companyUsers && user) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return {
            value: userItem.user_id,
            label: userItem.short_name,
            active: userItem.active,
          };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
        active: true,
      });

      options.push({
        value: '',
        label: 'Todos',
        active: true,
      });

      setUsersOptions(options);
    }
  }, [companyUsers, user]);

  useEffect(() => {
    if (user) {
      const userStorage = localStorage.getItem(
        '@Diretiva:schedule:filter:user'
      );

      if (userStorage) {
        setSelectedUser(JSON.parse(userStorage));
        setFilterUser(JSON.parse(userStorage));
      } else {
        setSelectedUser({
          value: user.id,
          label: user.short_name,
        });
        setFilterUser({
          value: user.id,
          label: user.short_name,
        });
      }
    }
  }, [user]);

  // useEffect(() => {
  //   if (appointment) {
  //     setAppointmentUpdate(appointment);
  //   }
  // }, [appointment]);

  const conclusionOptions = useMemo(() => {
    return [
      { value: '', label: 'Todos' },
      { value: 0, label: 'Não concluído' },
      { value: 1, label: 'Concluído' },
    ];
  }, []);

  const alterView = useCallback(() => {
    if (matchParamsId) {
      history.push('/schedule');
    }
    if (appointments.length > 0) {
      setShowTable(!showTable);
    }
  }, [showTable, matchParamsId, appointments.length]);

  const getDetails = useCallback(
    async (id, index) => {
      const appointmentItem = appointments.find(item => item.id === id);

      const [day, month, year] = appointmentItem.deadline_date.split('/');
      const date = new Date(`${year}-${month}-${day}T23:59:00.000Z`);
      date.toISOString();
      setAppointmentUpdate({
        ...appointmentItem,
        deadline_date: new Date(date),
      });
      setEndDeadlineForDatePicker(new Date(date));

      setAppointment(appointmentItem);
      setAppointmentIndex(index);
      alterView();

      if (appointmentItem.read === false) {
        await api.put(`schedule/read/${appointmentItem.id}`);
      }
    },
    [appointments, alterView]
  );

  const handleFilter = useCallback(
    data => {
      setCurrentPage(1);

      setSelectedUser(usersOptions.find(option => option.value === data.user));
      localStorage.setItem(
        '@Diretiva:schedule:filter:user',
        JSON.stringify(usersOptions.find(option => option.value === data.user))
      );

      setDescriptionSearch(data.appointment);
      localStorage.setItem(
        '@Diretiva:schedule:filter:description',
        data.appointment
      );

      setStartPeriod(data.start);
      setStartPeriodForDatePicker(data.start);
      localStorage.setItem('@Diretiva:schedule:filter:start', data.start);

      setEndPeriod(data.end);
      setEndPeriodForDatePicker(data.end);
      localStorage.setItem('@Diretiva:schedule:filter:end', data.end);

      setStartDeadline(data.start_deadline);
      setStartDeadlineForDatePicker(data.start_deadline);
      localStorage.setItem(
        '@Diretiva:schedule:filter:deadline:start',
        data.start_deadline
      );

      setEndDeadline(data.end_deadline);
      setEndDeadlineForDatePicker(data.end_deadline);
      localStorage.setItem(
        '@Diretiva:schedule:filter:deadline:end',
        data.end_deadline
      );
      if (data.end_deadline && data.end_deadline !== new Date()) {
        setIsChecked(false);
      }

      setSelectedConclusion(
        conclusionOptions.find(option => option.value === data.conclusion)
      );
      localStorage.setItem(
        '@Diretiva:schedule:filter:conclusion',
        JSON.stringify(
          conclusionOptions.find(option => option.value === data.conclusion)
        )
      );
    },
    [usersOptions, conclusionOptions]
  );

  const resetFilter = useCallback(() => {
    if (user) {
      setCurrentPage(1);

      filterRef.current.setFieldValue('user', {
        value: user.id,
        label: user.short_name,
      });
      setSelectedUser({
        value: user.id,
        label: user.short_name,
      });
      setFilterUser({
        value: user.id,
        label: user.short_name,
      });
      localStorage.removeItem('@Diretiva:schedule:filter:user');

      filterRef.current.clearField('appointment');
      setDescriptionSearch('');
      localStorage.removeItem('@Diretiva:schedule:filter:description');

      filterRef.current.clearField('start');
      setStartPeriod(null);
      setStartPeriodForDatePicker(null);
      localStorage.removeItem('@Diretiva:schedule:filter:start');

      setEndPeriod(null);
      setEndPeriodForDatePicker(null);
      localStorage.removeItem('@Diretiva:schedule:filter:end');

      filterRef.current.clearField('deadline');
      setStartDeadline(null);
      setStartDeadlineForDatePicker(null);
      localStorage.removeItem('@Diretiva:schedule:filter:deadline:start');

      setEndDeadline(null);
      setEndDeadlineForDatePicker(null);
      localStorage.removeItem('@Diretiva:schedule:filter:deadline:end');

      setIsChecked(false);

      filterRef.current.setFieldValue('conclusion', {
        value: 0,
        label: 'Não concluído',
      });
      setSelectedConclusion({
        value: 0,
        label: 'Não concluído',
      });
      localStorage.removeItem('@Diretiva:schedule:filter:conclusion');
    }
  }, [filterRef, user]);

  const handleFilterUsersOptions = useCallback(
    value => {
      if (user && companyUsers) {
        if (value === true) {
          setUsersOptions(oldUsersOptions =>
            oldUsersOptions.filter(userItem => userItem.active !== false)
          );
        } else {
          const options = companyUsers
            .filter(userItem => userItem.user_id !== user.id)
            .filter(userItem => userItem.user_id !== -1)
            .map(userItem => {
              return {
                value: userItem.user_id,
                label: userItem.short_name,
                active: userItem.active,
              };
            });

          options.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });

          options.unshift({
            value: user.id,
            label: user.short_name,
            active: true,
          });

          options.push({
            value: '',
            label: 'Todos',
            active: true,
          });

          setUsersOptions(options);
        }
      }
    },
    [companyUsers, user]
  );

  const handlePage = useCallback(
    page => {
      if (page === 0) {
        setCurrentPage(1);
      } else if (page > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(page);
      }
    },

    [totalPages]
  );

  const handlePrevItem = useCallback(async () => {
    if (appointmentIndex !== 0) {
      setAppointmentIndex(appointmentIndex - 1);
      setAppointment(appointments[appointmentIndex - 1]);
      if (appointments[appointmentIndex - 1].read === false) {
        await api.put(`schedule/read/${appointments[appointmentIndex - 1].id}`);
      }
    } else {
      setAppointmentIndex(appointments.length - 1);
      setAppointment(appointments[appointments.length - 1]);
      if (appointments[appointments.length - 1].read === false) {
        await api.put(
          `schedule/read/${appointments[appointments.length - 1].id}`
        );
      }
    }
  }, [appointmentIndex, appointments]);

  const handleNextItem = useCallback(async () => {
    if (appointmentIndex !== appointments.length - 1) {
      setAppointmentIndex(appointmentIndex + 1);
      setAppointment(appointments[appointmentIndex + 1]);
      if (appointments[appointmentIndex + 1].read === false) {
        await api.put(`schedule/read/${appointments[appointmentIndex + 1].id}`);
      }
    } else {
      setAppointmentIndex(0);
      setAppointment(appointments[0]);
      if (appointments[0].read === false) {
        await api.put(`schedule/read/${appointments[0].id}`);
      }
    }
  }, [appointmentIndex, appointments]);

  const handleDelete = useCallback(
    async id => {
      try {
        setDeleteLoading(true);

        await api.delete(`schedule/${id}`);

        setAppointments(oldAppoinments =>
          oldAppoinments.filter(item => item.id !== id)
        );

        alterView();

        loadSchedule();

        toast.success('Compromisso deletado com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      } catch (err) {
        toast.error('Falha ao deletar compromisso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setDeleteLoading(false);
      }
    },
    [alterView, loadSchedule]
  );

  const confirmDelete = useCallback(() => {
    if (user && companyUser) {
      if (appointment.sender?.id !== user?.id && companyUser.level < 9) {
        toast.warn('Somente o criador do compromisso pode deletá-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <ConfirmWindow
                onClick={() => handleDelete(appointment.id)}
                onClose={onClose}
              />
            );
          },
          closeOnEscape: false,
          closeOnClickOutside: false,
        });
      }
    }
  }, [handleDelete, appointment, user, companyUser]);

  const handleEdit = useCallback(() => {
    if ((user, companyUser)) {
      if (appointment.sender) {
        if (appointment.sender?.id !== user?.id && companyUser.level < 9) {
          toast.warn('Somente o criador do compromisso pode editá-lo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          history.push('/schedule/edit', { id: appointment.id });
        }
      } else if (companyUser.level === 9) {
        history.push('/schedule/edit', { id: appointment.id });
      } else {
        toast.warn('Somente o criador do compromisso pode editá-lo.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  }, [appointment, user, companyUser]);

  const [copyFile, setCopyFile] = useState(null);

  const handleFeedback = async (data, feedbackReport) => {
    if (company && user) {
      let blob = null;
      if (copyFile) {
        blob = base64ToBlob(copyFile);
      }
      try {
        setFeedbackLoading(true);

        if (copyFile) {
          data.file = {};
          data.file.name = 'feedback.png';
          data.file.type = 'image/png';
        }

        const schema = Yup.object().shape({
          feedback_message: Yup.string().when('file', {
            is: value => value !== undefined,
            then: Yup.string(),
            otherwise: Yup.string().required('A mensagem é obrigatória'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const feedbackData = {
          company_id: company.id,
          schedule_id: appointment.id,
          user_id: user.id,
          feedback: feedbackReport,
          content:
            data.file && data.feedback_message === ''
              ? data.file.name
              : data.feedback_message,
          type: data.file ? 1 : 0,
          file_name: data.file ? data.file.name : null,
        };

        if (data.file) {
          const formData = new FormData();

          if (!copyFile) {
            formData.append('file', data.file);
          } else {
            formData.append('file', blob, 'feedback.png');
            setCopyFile(null);
          }

          const fileResponse = await api.post('files/upload', formData, {
            params: {
              prefix: 'Schedule_Feedback',
            },
          });

          const { blobName } = fileResponse.data;

          feedbackData.link = blobName;
        }

        await api.post('schedule/feedback', feedbackData);

        const response = await api.get(`schedule/${appointment.id}`);

        const scheduleData = response.data;

        const { start_date, deadline_date, conclusion_date } = scheduleData;

        scheduleData.start_date = format(parseISO(start_date), 'dd/MM/yyyy');
        const deadlineDateObj = new Date(deadline_date);
        scheduleData.deadline_date = deadline_date
          ? format(
              new Date(
                deadlineDateObj.getUTCFullYear(),
                deadlineDateObj.getUTCMonth(),
                deadlineDateObj.getUTCDate()
              ),
              'dd/MM/yyyy'
            )
          : null;
        scheduleData.conclusion_date = conclusion_date
          ? format(parseISO(conclusion_date), "dd/MM/yyyy 'às' HH:mm")
          : null;
        scheduleData.start_hour = format(parseISO(start_date), 'HH:mm');
        scheduleData.deadline_hour = deadline_date
          ? format(parseISO(deadline_date), 'HH:mm')
          : null;

        const feedbackFormatted = scheduleData.feedback.map(feedbackItem => ({
          ...feedbackItem,
          feedback_date_formatted: format(
            parseISO(feedbackItem.feedback_date),
            "dd/MM/yyyy 'às' HH:mm"
          ),
        }));

        scheduleData.feedback = feedbackFormatted;

        setAppointments(
          appointments.map(appointmentItem =>
            appointmentItem.id === data.id ? data : appointmentItem
          )
        );

        setAppointment(scheduleData);

        feedbackRef.current.clearField('feedback_message');

        feedbackRef.current.setErrors({});

        setUploadFile([]);
        setFeedbackLoading(false);

        toast.success('Feedback salvo com sucesso.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          });

          feedbackRef.current.setErrors(errorMessages);
        } else {
          toast.error('Falha ao salvar feedback.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
        setUploadFile([]);
        setFeedbackLoading(false);
      }
    }
  };

  const alertFeedback = async data => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <OptionWindow
            yesClick={() => handleFeedback(data, true)}
            noClose={() => handleFeedback(data, false)}
            Close={() => onClose()}
            body="Deseja notificar o autor/destinatário?"
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const handleDeleteFeedback = useCallback(
    async (id, scheduleId, userId, date) => {
      if (user) {
        if (user.id === userId) {
          if (isToday(parseISO(date))) {
            setDeleteFeedbackLoading(true);
            try {
              await api.delete(`schedule/feedback/${id}`);

              setAppointments(
                appointments.map(item =>
                  item.id === scheduleId
                    ? {
                        ...item,
                        feedback: item?.feedback?.filter(
                          feedback => feedback.id !== id
                        ),
                      }
                    : item
                )
              );

              setAppointment(oldAppointment => ({
                ...oldAppointment,
                feedback: oldAppointment?.feedback?.filter(
                  feedback => feedback.id !== id
                ),
              }));

              toast.success('Mensagem deletada com sucesso.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } catch {
              toast.error('Falha ao deletar mensagem.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            } finally {
              setDeleteFeedbackLoading(false);
            }
          } else {
            toast.warn('Não é possivel deletar mensagens antigas.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        } else {
          toast.warn('Somente o criador da mensagem pode deletá-lo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [user, appointments]
  );

  const confirmRemoveFeedback = useCallback(
    (id, scheduleId, userId, date) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmWindow
              onClick={() => handleDeleteFeedback(id, scheduleId, userId, date)}
              onClose={onClose}
            />
          );
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    },
    [handleDeleteFeedback]
  );

  const handleDone = useCallback(
    async (value, documentData) => {
      let documentForProtocol = null;

      if (documentData) {
        const { competence, uploadFile: file } = documentData;

        documentForProtocol = {
          competence,
        };

        if (file.name) {
          const formData = new FormData();

          formData.append('file', file);

          const fileResponse = await api.post('files/upload', formData, {
            params: {
              prefix: 'Process_Phase',
            },
          });

          const { blobName } = fileResponse.data;

          documentForProtocol.file = file.name;
          documentForProtocol.file_url = blobName;
        }
      }

      setAppointments(
        produce(appointments, draft => {
          draft.map(item => {
            if (item.id === appointment.id) {
              item.done = value;

              item.conclusion_date = format(
                new Date(),
                "dd/MM/yyyy 'às' HH:mm "
              );

              if (documentForProtocol) {
                item.document_competence = documentForProtocol.competence;
                item.file = documentForProtocol.file;
                item.file_url = documentForProtocol.file_url;
              }

              return item;
            }

            return item;
          });
        })
      );

      setAppointment(
        produce(appointment, draft => {
          draft.done = value;

          draft.conclusion_date = format(new Date(), "dd/MM/yyyy 'às' HH:mm ");

          if (documentForProtocol) {
            draft.document_competence = documentForProtocol.competence;
            draft.file = documentForProtocol.file;
            draft.file_url = documentForProtocol.file_url;
          }
        })
      );

      await api.put(`schedule/done/${appointment.id}`, {
        done: value,
        documentForProtocol,
      });
    },
    [appointments, appointment]
  );

  const handleRequestConclusion = useCallback(
    async (value, documentData) => {
      let documentForProtocol = null;

      if (documentData) {
        const { competence, uploadFile: file } = documentData;

        documentForProtocol = {
          competence,
        };

        if (file.name) {
          const formData = new FormData();

          formData.append('file', file);

          const fileResponse = await api.post('files/upload', formData, {
            params: {
              prefix: 'Process_Phase',
            },
          });

          const { blobName } = fileResponse.data;

          documentForProtocol.file = file.name;
          documentForProtocol.file_url = blobName;
        }
      }

      setAppointments(
        produce(appointments, draft => {
          draft.map(item => {
            if (item.id === appointment.id) {
              item.request_conclusion = value;

              if (documentForProtocol) {
                item.document_competence = documentForProtocol.competence;
                item.file = documentForProtocol.file;
                item.file_url = documentForProtocol.file_url;
              }

              return item;
            }

            return item;
          });
        })
      );

      setAppointment(
        produce(appointment, draft => {
          draft.request_conclusion = value;

          if (documentForProtocol) {
            draft.document_competence = documentForProtocol.competence;
            draft.file = documentForProtocol.file;
            draft.file_url = documentForProtocol.file_url;
          }
        })
      );

      await api.put(`schedule/request-conclusion/${appointment.id}`, {
        request_conclusion: value,
        documentForProtocol,
      });
    },
    [appointments, appointment]
  );

  const handleRequestExtension = useCallback(
    async value => {
      if (appointment.deadline >= new Date().setHours(23, 59, 59, 999)) {
        setAppointments(
          produce(appointments, draft => {
            draft.map(item => {
              if (item.id === appointment.id) {
                item.request_extension = value;

                return item;
              }

              return item;
            });
          })
        );

        setAppointment(
          produce(appointment, draft => {
            draft.request_extension = value;
          })
        );

        await api.put(`schedule/request-extension/${appointment.id}`, {
          request_extension: value,
        });
      } else {
        setAppointment(
          produce(appointment, draft => {
            draft.request_extension = false;
          })
        );

        toast.warn(
          'O compromisso já está atrasado. Não é possível prorrogá-lo.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      }
    },
    [appointments, appointment]
  );

  const handleToggleScheduler = useCallback(() => {
    setToggleScheduler(!toggleScheduler);
  }, [toggleScheduler]);

  const sort = useCallback(
    key => {
      let direction = 'asc';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'asc'
      ) {
        direction = 'desc';
      }
      const element = document.getElementById(key);
      element.classList.add(direction);
      setSortConfig({ key, direction });
    },
    [sortConfig]
  );

  useEffect(() => {
    if (uploadFile.length !== 0) {
      feedbackRef.current.submitForm();
    }
  }, [uploadFile]);

  const openFile = useCallback(async blobName => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    const fileURL = URL.createObjectURL(response.data);

    window.open(fileURL, '_blank');
  }, []);

  const downloadFile = useCallback(async (blobName, fileName) => {
    const response = await api.get('files/download', {
      params: {
        blobName,
      },
      responseType: 'blob',
    });

    fileDownload(response.data, fileName);
  }, []);

  const handlePrevUser = useCallback(() => {
    resetFilter();

    const index = usersOptions
      .map(e => {
        return e.value;
      })
      .indexOf(selectedUser.value);

    if (index === 0) {
      setSelectedUser(usersOptions[usersOptions.length - 1]);
      setFilterUser(usersOptions[usersOptions.length - 1]);
      localStorage.setItem(
        '@Diretiva:schedule:filter:user',
        JSON.stringify(usersOptions[usersOptions.length - 1])
      );
    } else {
      setSelectedUser(usersOptions[index - 1]);
      setFilterUser(usersOptions[index - 1]);
      localStorage.setItem(
        '@Diretiva:schedule:filter:user',
        JSON.stringify(usersOptions[index - 1])
      );
    }
  }, [usersOptions, selectedUser, resetFilter]);

  const handleNextUser = useCallback(() => {
    resetFilter();

    const index = usersOptions
      .map(e => {
        return e.value;
      })
      .indexOf(selectedUser.value);

    if (index + 1 === usersOptions.length) {
      setSelectedUser(usersOptions[0]);
      setFilterUser(usersOptions[0]);
      localStorage.setItem(
        '@Diretiva:schedule:filter:user',
        JSON.stringify(usersOptions[0])
      );
    } else {
      setSelectedUser(usersOptions[index + 1]);
      setFilterUser(usersOptions[index + 1]);
      localStorage.setItem(
        '@Diretiva:schedule:filter:user',
        JSON.stringify(usersOptions[index + 1])
      );
    }
  }, [usersOptions, selectedUser, resetFilter]);

  const handleOpenPopModal = useCallback(async () => {
    try {
      setPopLoading(true);

      const response = await api.get(`/pop/${appointment.related_pop}`);

      popForModal.current = response.data;
      setPopLoading(false);
      setPopModalIsOpen(true);
    } catch (err) {
      setPopLoading(false);
      toast.error('Falha ao buscar POP', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  }, [appointment]);

  const [phaseDataLoading, setPhaseDataLoading] = useState(false);
  const [visiblePopUp, setVisiblePopUp] = useState(false);
  const handleCheckCheckboxConclusion = async (checked, type) => {
    if (checked === true && appointment.document_required) {
      try {
        setPhaseDataLoading(true);

        functionToConcludeModal.current =
          type === 0 ? handleDone : handleRequestConclusion;

        const response = await api.get(
          `/processes/phases/${appointment.phase_id}`
        );

        if (response.data) {
          infoToConcludeModal.current = {
            client: response.data.process.client.name,
            document: response.data.doc.description,
          };
        } else {
          infoToConcludeModal.current = {
            client: appointment.description.split(' - ')[2],
            document: appointment.description.split(' - ')[1],
          };
        }
      } catch {
        toast.error('Falha ao buscar dados da etapa.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        infoToConcludeModal.current = {
          client: appointment.description.split(' - ')[2],
          document: appointment.description.split(' - ')[1],
        };
      } finally {
        setConcludePhaseModalIsOpen(true);
        setPhaseDataLoading(false);
      }
    } else if (type === 0) {
      handleDone(checked);
    } else {
      handleRequestConclusion(checked);
    }
  };

  const Postpone = async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            message={`Deseja adiar em ${
              appointment.extension_days ? appointment.extension_days : 3
            } dias esta tarefa?`}
            onClick={async () => {
              if (appointment.priority !== 0) {
                toast.error('A data desta não pode ser adiada.', {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }
              const response = await api.patch(`schedule/${appointment.id}`, {
                days: appointment.extension_days
                  ? appointment.extension_days
                  : 3,
                priority: 1,
              });

              if (response.status !== 204) {
                toast.error('A data desta não pode ser adiada.', {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              }

              const [dia, mes, ano] = appointment.deadline_date.split('/');
              const deadline_date = new Date(`${ano}-${mes}-${dia}`);
              deadline_date.setUTCHours(23, 59, 59, 999);
              const extensionDays = appointment.extension_days
                ? appointment.extension_days
                : 3;
              deadline_date.setDate(deadline_date.getDate() + extensionDays);

              setAppointment({
                ...appointment,
                deadline_date: deadline_date.toLocaleDateString('pt-BR'),
              });

              toast.success('Tarefa adiada com sucesso.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  const [p, setP] = useState([]);
  const formRef = useRef(null);
  const formVisualizationRef = useRef(null);

  const handleSubmit = async data => {
    try {
      const updatedP = p.map((item, index) => {
        const answer = data[`question-${index}`];
        return { ...item, answer };
      });

      await Promise.all(
        updatedP.map(async item => {
          await api.put(`processes/questions/${item.id}`, {
            answer: item.answer,
            createFeedback: true,
          });
          const feedbackData = {
            company_id: company.id,
            schedule_id: appointment.id,
            user_id: user.id,
            feedback: false,
            content: `Pergunta "${item.question}" com resposta atualizada para "${item.answer}"`,
            type: 0,
          };

          await api.post('schedule/feedback', feedbackData);
        })
      );

      toast.success('Perguntas alteradas com sucesso.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setVisiblePopUp(false);
    } catch (error) {
      toast.error('Erro ao alterar as resposta das Perguntas.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleVisualizationSubmit = async data => {
    try {
      await api.put(`schedule/${appointmentUpdate.id}`, data);
      toast.success('Sucesso ao salvar alterações', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error) {
      toast.error('Falha ao salvar alterações', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const openQuestions = async () => {
    try {
      if (!appointment.process_id) {
        toast.warn('Nenhuma Pergunta vinculada.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }
      const response = await api.get(
        `processes/questions/${appointment.process_id}`
      );

      const { data } = response;

      if (data.length === 0) {
        toast.warn('Nenhuma Pergunta vinculada.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }

      setP(data);
      setVisiblePopUp(true);
    } catch (error) {
      toast.warn('Nenhuma Pergunta foi encontrado.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const popUpBody = (
    <Form ref={formRef} onSubmit={handleSubmit}>
      {p.map((item, index) => (
        <section key={index} id={`section-${index}`}>
          <Input
            name={`question-${index}`}
            className="question"
            label={item.question}
            defaultValue={item.answer}
          />
        </section>
      ))}
      <button type="submit">Salvar</button>
    </Form>
  );

  return (
    <>
      <PopUp
        visible={visiblePopUp}
        setVisible={setVisiblePopUp}
        body={popUpBody}
        title="Alterar dados do Processo"
        icon={<FaCog size="18" color="#01579b" />}
      />
      <Container>
        <Header>
          <div>
            <FaCalendarAlt size={20} color="#44546a" />
            <h1>Agenda</h1>
          </div>
          <aside>
            <button type="button" onClick={handleToggleScheduler}>
              <FaClock size={20} color="#44546a" />
            </button>

            <Link to="/">
              <FaTimes size={20} color="#44546a" />
            </Link>
          </aside>
        </Header>

        <Controls>
          <button type="button" onClick={alterView}>
            <FaEye />
            <span>Visualização</span>
          </button>
          <Link to={{ pathname: '/schedule/new', state: { id: null } }}>
            <FaPlus />
            <span>Novo</span>
          </Link>
          {showTable ? (
            <button type="button" onClick={resetFilter}>
              <FaEraser />
              <span>Limpar filtros</span>
            </button>
          ) : (
            <>
              <button type="button" onClick={handleEdit}>
                <FaEdit />
                <span>Editar</span>
              </button>
              <button type="button" onClick={confirmDelete}>
                <FaTrash />
                <span>Excluir</span>
              </button>

              {!matchParamsId && (
                <div>
                  <button type="button" onClick={handlePrevItem}>
                    <FaChevronLeft />
                  </button>

                  <span>
                    {appointmentIndex + 1} de {appointments.length}
                  </span>

                  <button type="button" onClick={handleNextItem}>
                    <FaChevronRight />
                  </button>
                </div>
              )}
              {appointment && appointment.related_pop && (
                <button
                  type="button"
                  onClick={handleOpenPopModal}
                  className="how-make"
                >
                  <FaInfoCircle />
                  <span>Como fazer</span>
                </button>
              )}
              {appointment?.priority === 0 &&
              appointment?.extension_days > 0 ? (
                <button type="button" onClick={Postpone}>
                  <FaClock size={15} color="#44546a" />
                  <span>Adiar</span>
                </button>
              ) : null}
              {(appointment?.recipient_id === user.id ||
                companyUser.level === 9) &&
              appointment?.process_id ? (
                <button type="button" onClick={() => openQuestions()}>
                  <FaPencilAlt />
                  <span>Processo</span>
                </button>
              ) : null}
            </>
          )}
        </Controls>
        {showTable && filterUser && (
          <Filter ref={filterRef} onSubmit={handleFilter}>
            <button
              className="arrowUser"
              type="button"
              onClick={handlePrevUser}
            >
              <FaChevronLeft />
            </button>
            <SelectWithFilterActiveCheckbox
              label="Usuário"
              name="user"
              className="user"
              options={usersOptions}
              value={filterUser}
              handleFilter={handleFilterUsersOptions}
              onChange={e => setFilterUser(e)}
            />
            <button
              className="arrowUser"
              type="button"
              onClick={handleNextUser}
            >
              <FaChevronRight />
            </button>

            <Input
              name="appointment"
              className="appointment"
              label="Compromisso"
              defaultValue={descriptionSearch}
            />

            <DateContainer className="period">
              <label>Data de criação</label>
              <div>
                <DatePicker
                  name="start"
                  selected={startPeriodForDatePicker}
                  onChange={date => setStartPeriodForDatePicker(date)}
                />
                <span>até</span>
                <DatePicker
                  name="end"
                  selected={endPeriodForDatePicker}
                  onChange={date => setEndPeriodForDatePicker(date)}
                />
              </div>
            </DateContainer>
            <DateContainer className="deadline">
              <label>
                Prazo
                <Checkbox
                  id="today"
                  name="today"
                  className="today"
                  label="Hoje"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
              </label>
              <div>
                <DatePicker
                  name="start_deadline"
                  selected={startDeadlineForDatePicker}
                  onChange={date => {
                    if (date && date !== new Date()) {
                      setIsChecked(false);
                    }
                    setStartDeadlineForDatePicker(date);
                  }}
                />
                <span>até</span>
                <DatePicker
                  name="end_deadline"
                  selected={endDeadlineForDatePicker}
                  onChange={date => {
                    if (date && date !== new Date()) {
                      setIsChecked(false);
                    }

                    setEndDeadlineForDatePicker(date);
                  }}
                />
              </div>
            </DateContainer>
            {/* </div> */}

            <Select
              label="Conclusão"
              name="conclusion"
              className="conclusion"
              options={conclusionOptions}
              defaultValue={selectedConclusion}
            />

            <button type="submit">
              <FaSearch />
            </button>
          </Filter>
        )}
        {appointmentsLoading ||
        !user ||
        !companyUser ||
        !companyUsers ||
        !company ||
        !selectedUser ? (
          <TableLoading />
        ) : (
          <Content className="content">
            {showTable ? (
              <TableContainer>
                <thead>
                  <tr>
                    <th className="private" />
                    <th
                      id="start_date"
                      className="start_date sort"
                      onClick={() => sort('start_date')}
                    >
                      <div>
                        Data
                        {sortConfig.key !== 'start_date' && <FaSort />}
                        {sortConfig.key === 'start_date' &&
                          sortConfig.direction === 'asc' && <FaSortUp />}
                        {sortConfig.key === 'start_date' &&
                          sortConfig.direction === 'desc' && <FaSortDown />}
                      </div>
                    </th>
                    <th
                      id="deadline_date"
                      className="deadline_date sort"
                      onClick={() => sort('deadline_date')}
                    >
                      <div>
                        Prazo
                        {sortConfig.key !== 'deadline_date' && <FaSort />}
                        {sortConfig.key === 'deadline_date' &&
                          sortConfig.direction === 'asc' && <FaSortUp />}
                        {sortConfig.key === 'deadline_date' &&
                          sortConfig.direction === 'desc' && <FaSortDown />}
                      </div>
                    </th>
                    <th className="start_hour">Até</th>
                    <th className="sender">Autor</th>
                    <th className="recipient">Destinatário</th>
                    <th className="priority" />
                    <th
                      id="description"
                      className="description sort"
                      onClick={() => sort('description')}
                    >
                      <div>
                        Mensagem
                        {sortConfig.key !== 'description' && <FaSort />}
                        {sortConfig.key === 'description' &&
                          sortConfig.direction === 'asc' && <FaSortUp />}
                        {sortConfig.key === 'description' &&
                          sortConfig.direction === 'desc' && <FaSortDown />}
                      </div>
                    </th>
                    <th className="read">Lido</th>
                  </tr>
                </thead>
                <tbody>
                  {appointments.map((item, index) => (
                    <tr
                      key={String(item.id)}
                      onClick={() => getDetails(item.id, index)}
                      className="hover"
                    >
                      <td className="private">
                        {item.private ? (
                          <FaLock size={12} color="#01579B" />
                        ) : (
                          <FaLockOpen size={12} color="#00c853" />
                        )}
                      </td>

                      <td
                        className={`start_date ${
                          item.deadline < new Date().setUTCHours(0, 0, 0, 0)
                            ? 'late'
                            : item.deadline >=
                                startOfDay(
                                  new Date().setUTCHours(0, 0, 0, 0)
                                ) &&
                              item.deadline <=
                                addDays(
                                  new Date().setUTCHours(23, 59, 59, 999),
                                  1
                                )
                            ? 'warn'
                            : null
                        }`}
                      >
                        {item.start_date}
                      </td>

                      <td
                        className={`deadline_date ${
                          item.deadline < new Date().setUTCHours(0, 0, 0, 0)
                            ? 'late'
                            : item.deadline >=
                                startOfDay(
                                  new Date().setUTCHours(0, 0, 0, 0)
                                ) &&
                              item.deadline <=
                                addDays(
                                  new Date().setUTCHours(23, 59, 59, 999),
                                  1
                                )
                            ? 'warn'
                            : null
                        }`}
                      >
                        {item.deadline_date}
                      </td>

                      <td
                        className={`start_hour ${
                          item.deadline < new Date().setUTCHours(0, 0, 0, 0)
                            ? 'late'
                            : item.deadline >=
                                startOfDay(
                                  new Date().setUTCHours(0, 0, 0, 0)
                                ) &&
                              item.deadline <=
                                addDays(
                                  new Date().setUTCHours(23, 59, 59, 999),
                                  1
                                )
                            ? 'warn'
                            : null
                        }`}
                      >
                        {item.deadline_hour}
                      </td>

                      <td className="sender">
                        {item.sender == null
                          ? 'null'
                          : item.sender.short_name.split(' ')[0]}
                      </td>
                      <td className="recipient">
                        {item.recipient?.short_name.split(' ')[0]}
                      </td>

                      <td className="priority">
                        {item.priority === 1 && (
                          <FaExclamationTriangle color="#E53935" />
                        )}
                      </td>
                      <td className="description">{item.description}</td>
                      <td className="read">
                        {item.read && <FaCheck size={12} color="#01579B" />}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableContainer>
            ) : (
              <DetailsContainer
                onSubmit={handleVisualizationSubmit}
                ref={formVisualizationRef}
                initialData={appointmentUpdate}
              >
                {appointment && (
                  <>
                    <AppointmentInfo>
                      <h4>AGENDA</h4>
                      <section>
                        <div className="start_date">
                          <label>Data de início</label>
                          <input
                            name="start_date"
                            value={appointment.start_date || ''}
                            readOnly
                          />
                        </div>

                        <div className="start_hour">
                          <label>Hora</label>
                          <input
                            name="start_hour"
                            value={appointment.start_hour || ''}
                            readOnly
                          />
                        </div>

                        <div className="deadline_date">
                          <label>Prazo</label>
                          <input
                            name="deadline_date"
                            value={appointment.deadline_date || ''}
                            readOnly
                          />
                        </div>

                        {/* <DatePicker
                          name="deadline_date"
                          className="deadline_date"
                          label="Prazo"
                          onChange={e => {
                            setAppointmentUpdate(prevState => ({
                              ...prevState,
                              deadline_date: e,
                            }));
                            handleVisualizationSubmit({
                              ...appointmentUpdate,
                              deadline_date: e,
                            });
                          }}
                          selected={appointmentUpdate?.deadline_date}
                          readOnly={!companyUser.level === 9}
                        /> */}

                        <div className="deadline_hour">
                          <label>Hora</label>
                          <input
                            name="deadline_hour"
                            value={appointment.deadline_hour || ''}
                            readOnly
                          />
                        </div>

                        {appointment.recipient &&
                        appointment.recipient.id !== 2 ? (
                          <div className="recipient">
                            <label>Na agenda de</label>
                            <input
                              name="recipient"
                              value={appointment.recipient.short_name || ''}
                              readOnly
                            />
                          </div>
                        ) : (
                          <div className="recipient">
                            <label>Na agenda de</label>
                            <input name="recipient" value="SISTEMA" readOnly />
                          </div>
                        )}

                        <Select
                          name="priority"
                          className="priority"
                          label="Prioridade"
                          options={priorityOptions}
                          isDisabled={companyUser.level < 9}
                          placeholder="Selecione uma prioridade"
                          value={priorityOptions.find(
                            option =>
                              option.value ===
                              (appointmentUpdate?.priority || 0)
                          )}
                          onChange={e => {
                            setAppointmentUpdate({
                              ...appointmentUpdate,
                              priority: e.value,
                            });
                          }}
                          onBlur={() =>
                            formVisualizationRef.current.submitForm()
                          }
                        />
                      </section>
                      <section>
                        <div className="description">
                          <label>Mensagem</label>
                          {appointment.is_json_description ? (
                            <TextAreaAsPlainText>
                              {appointment.description || ''}
                            </TextAreaAsPlainText>
                          ) : (
                            <TextareaAutosize
                              name="description"
                              value={appointment.description || ''}
                              readOnly
                            />
                          )}
                        </div>
                      </section>
                      <section>
                        {appointment.sender !== null ? (
                          <div className="sender">
                            <label>Quem escreveu</label>
                            <input
                              name="sender"
                              value={appointment.sender.short_name || ''}
                              readOnly
                            />
                          </div>
                        ) : (
                          <div className="sender">
                            <label>Quem escreveu</label>
                            <input name="sender" value="SISTEMA" readOnly />
                          </div>
                        )}
                        {appointment.type === 0 && (
                          <div className="type">
                            <label>Tipo</label>
                            <input name="type" value="Tarefa" readOnly />
                          </div>
                        )}
                        {appointment.type === 3 && (
                          <div className="type">
                            <label>Tipo</label>
                            <input name="type" value="KPI" readOnly />
                          </div>
                        )}
                        {appointment.type === 4 && (
                          <div className="type">
                            <label>Tipo</label>
                            <input name="type" value="Aviso" readOnly />
                          </div>
                        )}
                        {appointment.type === 5 && (
                          <div className="type">
                            <label>Tipo</label>
                            <input name="type" value="Suporte" readOnly />
                          </div>
                        )}
                        {appointment.type === 6 && (
                          <div className="type">
                            <label>Tipo</label>
                            <input
                              name="type"
                              value="Etapa de Processo"
                              readOnly
                            />
                          </div>
                        )}
                        {appointment.type === 7 && (
                          <div className="type">
                            <label>Tipo</label>
                            <input
                              name="type"
                              value="Controle de Vencimento"
                              readOnly
                            />
                          </div>
                        )}
                        {appointment.type === 8 && (
                          <div className="type">
                            <label>Tipo</label>
                            <input name="type" value="Atendimento" readOnly />
                          </div>
                        )}
                        {appointment.type === 2 && (
                          <>
                            <div className="type">
                              <label>Tipo</label>
                              <input
                                name="type"
                                value="Conta a pagar"
                                readOnly
                              />
                            </div>

                            {appointment.supplier && (
                              <div className="supplier">
                                <label>Fornecedor</label>
                                <input
                                  name="supplier"
                                  value={appointment.supplier.name || ''}
                                  readOnly
                                />
                              </div>
                            )}

                            <div className="price">
                              <label>Valor</label>
                              <input
                                name="price"
                                value={appointment.price || ''}
                                readOnly
                              />
                            </div>
                          </>
                        )}
                        {/* <div className="private checkbox">
                          <label>Privado</label>
                          <input
                            name="private"
                            checked={appointment.private}
                            type="checkbox"
                            disabled
                          />
                        </div> */}
                        <Checkbox
                          id="private"
                          name="private"
                          className="private checkbox"
                          label="Privado"
                          onBlur={() =>
                            formVisualizationRef.current.submitForm()
                          }
                          readOnly={!companyUser.level === 9}
                        />

                        {appointment.recipient_id === user.id &&
                          appointment.monitor === true &&
                          appointment.done === false && (
                            <div className="request_conclusion checkbox">
                              <label>Solicitar Conclusão</label>
                              <input
                                name="request_conclusion"
                                checked={appointment.request_conclusion}
                                type="checkbox"
                                onChange={e =>
                                  handleCheckCheckboxConclusion(
                                    e.target.checked,
                                    1
                                  )
                                }
                              />
                            </div>
                          )}

                        {((appointment.recipient_id === user.id &&
                          appointment.monitor === false) ||
                          companyUser.level === 9) && (
                          <div className="done checkbox">
                            <label>Concluído</label>
                            <input
                              name="done"
                              checked={appointment.done}
                              type="checkbox"
                              onChange={e =>
                                handleCheckCheckboxConclusion(
                                  e.target.checked,
                                  0
                                )
                              }
                            />
                          </div>
                        )}

                        {appointment.type === 6 &&
                          appointment.document_required &&
                          (appointment.request_conclusion ||
                            appointment.done) && (
                            <>
                              <div className="file">
                                <aside>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      openFile(appointment.file_url)
                                    }
                                    title="Visualizar arquivo"
                                  >
                                    <FaEye size={16} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      downloadFile(
                                        appointment.file_url,
                                        appointment.file
                                      )
                                    }
                                    title="Baixar arquivo"
                                  >
                                    <FaDownload size={16} />
                                  </button>
                                </aside>
                                <div>
                                  <label>Arquivo</label>
                                  <input
                                    type="text"
                                    value={appointment.file || ''}
                                    readOnly
                                  />
                                </div>
                              </div>
                              <div className="price">
                                <label>Competência</label>
                                <input
                                  name="price"
                                  value={appointment.document_competence || ''}
                                  readOnly
                                />
                              </div>
                            </>
                          )}

                        {appointment.done === true && (
                          <div className="conclusion_date">
                            <label>Concluído em</label>
                            <input
                              name="conclusion_date"
                              value={appointment.conclusion_date || ''}
                              disabled
                            />
                          </div>
                        )}

                        {appointment.recipient_id === user.id &&
                          appointment.monitor === true &&
                          appointment.done === false && (
                            <div className="request_extension checkbox">
                              <label>Solicitar Prorrogação</label>
                              <input
                                name="request_extension"
                                checked={appointment.request_extension}
                                type="checkbox"
                                onChange={e =>
                                  handleRequestExtension(e.target.checked)
                                }
                              />
                            </div>
                          )}
                      </section>
                    </AppointmentInfo>
                    <Feedback ref={feedbackRef} onSubmit={alertFeedback}>
                      <h4>FEEDBACK</h4>
                      <SendFeedback
                        uploadFile={uploadFile}
                        feedbackRef={feedbackRef}
                        copyFile={copyFile}
                        setCopyFile={setCopyFile}
                        setUploadFile={setUploadFile}
                      />
                      {appointment.feedback.length > 0 &&
                        appointment.feedback.map(feedback => (
                          <FeedbackItem key={feedback.id}>
                            <div className="delete">
                              <button
                                type="button"
                                onClick={() =>
                                  confirmRemoveFeedback(
                                    feedback.id,
                                    feedback.schedule_id,
                                    feedback.user.id,
                                    feedback.feedback_date
                                  )
                                }
                              >
                                <FaTrash size={14} />
                              </button>
                            </div>
                            <div className="date">
                              <label>Data/Hora</label>
                              <input
                                name="feedback_date_formatted"
                                value={feedback.feedback_date_formatted}
                                readOnly
                              />
                            </div>
                            <div className="user">
                              <label>Usuário</label>
                              <input
                                name="feedback_user"
                                value={feedback.user.short_name}
                                readOnly
                              />
                            </div>
                            <div className="content">
                              <label>Mensagem</label>
                              <TextareaAutosize
                                name="content"
                                value={feedback.content}
                                readOnly
                                maxRows={4}
                              />
                            </div>
                            {feedback.type === 1 && (
                              <div className="file">
                                <aside>
                                  <button
                                    type="button"
                                    onClick={() => openFile(feedback.link)}
                                    title="Visualizar arquivo"
                                  >
                                    <FaEye size={16} />
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      downloadFile(
                                        feedback.link,
                                        feedback.file_name
                                      )
                                    }
                                    title="Baixar arquivo"
                                  >
                                    <FaDownload size={16} />
                                  </button>
                                </aside>
                                <div>
                                  <label>Arquivo</label>
                                  <input
                                    type="text"
                                    value={feedback.file_name}
                                    readOnly
                                  />
                                </div>
                              </div>
                            )}
                          </FeedbackItem>
                        ))}
                    </Feedback>
                  </>
                )}
              </DetailsContainer>
            )}
          </Content>
        )}
        <Pagination
          loading={appointmentsLoading ? 1 : 0}
          currentPage={currentPage}
          pages={totalPages}
          totalDocs={totalAppointments}
          handlePage={handlePage}
        />
      </Container>
      {(deleteLoading ||
        feedbackLoading ||
        deleteFeedbackLoading ||
        popLoading ||
        phaseDataLoading) && <Loading />}
      {toggleScheduler && (
        <Scheduler isOpen={toggleScheduler} setIsOpen={handleToggleScheduler} />
      )}

      {concludePhaseModalIsOpen && (
        <ConcludePhaseModal
          setIsOpen={() => setConcludePhaseModalIsOpen(false)}
          onClick={functionToConcludeModal.current}
          info={infoToConcludeModal.current}
          type="schedule"
          notification={notification}
          setNotification={setNotification}
        />
      )}

      {popModalIsOpen && (
        <ShowPopModal
          isOpen={popModalIsOpen}
          setIsOpen={() => setPopModalIsOpen(false)}
          pop={popForModal.current}
        />
      )}
    </>
  );
};

export default List;

List.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.node,
    }).isRequired,
  }).isRequired,
};
