import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactTooltip from 'react-tooltip';
import { FaAngleDoubleRight, FaPlus, FaTrash } from 'react-icons/fa';
import { Scope } from '@unform/core';
import produce from 'immer';
import { toast } from 'react-toastify';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import cep from 'cep-promise';
import * as Yup from 'yup';
import { parse } from 'date-fns';

import {
  DatePicker,
  FormLoading,
  Input,
  InputMask,
  Select,
} from '~/components/Form';
import Loading from '~/components/Loading';
import LoadingMessage from '~/components/LoadingMessage';

import { useProcessAuth } from '~/hooks';
import api from '~/services/api';
import history from '~/services/history';

import { FormContainer, Button, Title, Options } from './styles';

const Form = () => {
  const {
    user,
    processModel,
    company,
    handleChangeClient,
    signIn,
  } = useProcessAuth();
  const { cpf, birthday } = user;

  const formRef = useRef();

  const [states, setStates] = useState(null);
  const [statesLoading, setStatesLoading] = useState(false);
  const [createProcessLoading, setCreateProcessLoading] = useState(false);
  const [cepLoading, setCepLoading] = useState(false);

  const [relateds, setRelateds] = useState([
    {
      document: cpf,
      birthday: birthday ? parse(birthday, 'dd/MM/yyyy', new Date()) : null,
      address: {
        id: uuid(),
      },
    },
  ]);

  const addressTypeOptions = useMemo(() => {
    return [
      { value: 1, label: 'Sede da Empresa' },
      { value: 2, label: 'Correspondência' },
      { value: 3, label: 'Outros' },
    ];
  }, []);

  useEffect(() => {
    function loadStates() {
      setStatesLoading(true);

      axios
        .get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
        .then(response => {
          const ufInitials = response.data.map(uf => ({
            value: uf.sigla,
            label: uf.nome,
          }));
          setStates(ufInitials);
        });

      setStatesLoading(false);
    }
    loadStates();
  }, []);

  const createClienteAndProcess = useCallback(
    async relatedsIds => {
      if (!user.id && !relatedsIds) return;

      try {
        setCreateProcessLoading(true);

        const clientResponse = await api.post('/process-portal/client', {
          company_id: company.id,
          relatedsIds: relatedsIds || [{ id: user.id, old_id: user.old_id }],
        });

        const client_id = clientResponse.data;

        let loggedUser = user;
        if (user.id) {
          handleChangeClient(client_id);
        } else {
          loggedUser = await signIn({ cpf, birthday });

          if (!loggedUser || Object.keys(loggedUser).length === 0) {
            toast.error('Houve algum erro, efetue o login novamente.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            history.push('/process-portal/signin');
          }
        }

        const processViewers = relatedsIds
          ? relatedsIds.map(item => item.id)
          : [loggedUser.id];
        const processCreatedResponse = await api.post(
          '/process-portal/process',
          {
            processModel_id: processModel.id,
            sender_name: loggedUser.name,
            company_id: company.id,
            client_id,
            relatedsIds: processViewers,
            isMoreThanOnePartner: processModel.process_type === 2,
          }
        );

        const processId = processCreatedResponse.data.id;

        history.push('/process-portal/edit', { id: processId });
      } catch (e) {
        toast.error(
          `Falha ao criar o processo, reinicie a página.${e.message}`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      } finally {
        setCreateProcessLoading(false);
      }
    },
    [user, company, handleChangeClient, processModel, signIn, cpf, birthday]
  );

  const [hasCreatedProcess, setHasCreatedProcess] = useState(false);
  useEffect(() => {
    if (hasCreatedProcess) return;

    if (user.client_id === -1 && processModel.process_type === 1) {
      createClienteAndProcess();
      setHasCreatedProcess(true);
    }
  }, [user, createClienteAndProcess, processModel, hasCreatedProcess]);

  const handleSubmit = useCallback(
    async data => {
      if (
        !user.id &&
        data.relateds[0].document !== user.cpf &&
        data.relateds[0].birthday !== user.birthday
      ) {
        toast.error(
          'O primeiro sócio deve ter os mesmos dados utilizado no login.',
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        formRef.current.setFieldError(`relateds[0].document`, 'CPF inválido.');
        formRef.current.setFieldError(
          `relateds[0].birthday`,
          'Aniversário inválido.'
        );
        return;
      }

      try {
        if (user.id) {
          data.relateds[0] = null;
        }

        const schema = Yup.object().shape({
          relateds: Yup.array().of(
            Yup.object()
              .shape({
                email: Yup.string().required('O email é obrigatório.'),
                phone: Yup.string().required('o telefone é obrigatório.'),
                name: Yup.string().required('O nome é obrigatório.'),
                document: Yup.string().required('O documento é obrigatório.'),
                birthday: Yup.string()
                  .required('O aniversário é obrigatório.')
                  .typeError('O aniversário é obrigatório.'),
                address: Yup.object().shape({
                  zipcode: Yup.string().required('O CEP é obrigatório.'),
                  street: Yup.string().required('O endereço é obrigatório.'),
                  number: Yup.string().required('O número é obrigatório.'),
                  neighborhood: Yup.string().required(
                    'O bairro é obrigatório.'
                  ),
                  state: Yup.string().required('O estado é obrigatório.'),
                  city: Yup.string().required('A cidade é obrigatória.'),
                }),
              })
              .nullable()
          ),
        });

        await schema.validate(data, { abortEarly: false });

        if (user.id) {
          data.relateds.shift();
        }

        data.relateds = data.relateds.map(item => ({
          ...item,
          document: item.document.replace(/[^0-9]+/g, ''),
          phone: item.phone.replace(/[^0-9]+/g, ''),
          address: {
            ...item.address,
            zipcode: item.address.zipcode.replace(/[^0-9]+/g, ''),
          },
        }));

        data.company_id = company.id;

        const relatedsCreated = await api.post('/process-portal/related', data);

        if (user.id) {
          relatedsCreated.data.unshift({ id: user.id, old_id: user.old_id });
        }

        await createClienteAndProcess(relatedsCreated.data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errorMessages = {};

          err.inner.forEach(error => {
            errorMessages[error.path] = error.message;
          });

          formRef.current.setErrors(errorMessages);

          toast.warn(
            'Tente rolar a pagina para verificar se existem campos a serem preenchidos.',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );

          toast.error(
            'Existem campos obrigatórios em vermelho que precisam ser preenchidos.',
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        } else {
          toast.error('Falha ao salvar processo.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      }
    },
    [company, user, createClienteAndProcess]
  );

  const handleChangeZipcode = useCallback(
    async (value, index) => {
      const unmaskedValue = value.replace(/[^0-9]+/g, '');

      if (unmaskedValue.length !== 8) {
        return;
      }

      try {
        setCepLoading(true);

        formRef.current.setErrors({});

        const cepData = await cep(unmaskedValue);

        const citiesOptions = await axios
          .get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${cepData.state}/municipios`
          )
          .then(response => {
            const cityNames = response.data.map(city => ({
              value: city.nome,
              label: city.nome,
            }));

            return cityNames;
          });

        setRelateds(
          produce(relateds, draft => {
            const formData = formRef.current.getData().relateds;

            const address = {
              id: uuid(),
              type: 1,
              zipcode: unmaskedValue,
              street: cepData.street,
              number: '',
              complement: '',
              neighborhood: cepData.neighborhood,
              state: cepData.state,
              city: cepData.city,
              citiesOptions,
            };

            formData[index].address = address;

            formData.forEach((relatedData, relatedDataIndex) => {
              draft[relatedDataIndex] = relatedData;
            });
          })
        );

        const numberEl = document.getElementById(`number${index}`);

        numberEl.focus();
      } catch (err) {
        formRef.current.setFieldError(
          `relateds[${index}].address.zipcode`,
          'CEP inválido.'
        );
      } finally {
        setCepLoading(false);
      }
    },
    [relateds]
  );

  const handleChangeState = useCallback(
    async (value, index) => {
      try {
        setCepLoading(true);

        const citiesOptions = await axios
          .get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`
          )
          .then(response => {
            const cityNames = response.data.map(city => ({
              value: city.nome,
              label: city.nome,
            }));

            return cityNames;
          });

        setRelateds(
          produce(relateds, draft => {
            draft[index].address.state = value;
            draft[index].address.citiesOptions = citiesOptions;
          })
        );
      } catch {
        toast.error('Falha ao carregas as cidades.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setCepLoading(false);
      }
    },
    [relateds]
  );

  const handleAddRelated = () => {
    setRelateds(oldRelateds =>
      oldRelateds.concat([{ address: { id: uuid() } }])
    );
  };

  const handleRemoveRelated = index => {
    setRelateds(
      produce(relateds, draft => {
        draft.splice(index, 1);
      })
    );
  };

  return (
    <>
      <ReactTooltip
        id="info"
        place="bottom"
        type="info"
        backgroundColor="#337ab7"
        effect="solid"
        multiline
      />
      {statesLoading || createProcessLoading || !states ? (
        <FormLoading />
      ) : (
        <>
          <FormContainer
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ relateds }}
            loading={cepLoading ? 1 : 0}
            className="content"
          >
            {relateds.map((item, index) => {
              if (index === 0 && user.id) {
                return (
                  <Scope path={`relateds[${index}]`} key={index}>
                    <Title>
                      <h4>Sócio {index + 1}</h4>
                    </Title>
                    <section>
                      <Input
                        name="userName"
                        label="Nome"
                        type="text"
                        value={user.name}
                        disabled
                      />
                      <Input
                        name="userDocument"
                        label="CPF"
                        type="text"
                        value={user.cpf}
                        disabled
                      />
                      <Input
                        name="userBirthday"
                        label="Data de nascimento"
                        type="text"
                        value={user.birthday}
                        disabled
                      />
                    </section>
                  </Scope>
                );
              }

              return (
                <Scope path={`relateds[${index}]`} key={item.address.id}>
                  <Title>
                    <h4>Sócio {index + 1}</h4>
                    {index !== 0 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveRelated(index)}
                      >
                        Remover
                        <FaTrash />
                      </button>
                    )}
                  </Title>
                  <section>
                    <Input name="name" label="Nome" type="text" />
                    <InputMask
                      name="document"
                      label="CPF"
                      type="text"
                      mask="999.999.999-99"
                    />
                    <DatePicker
                      name="birthday"
                      className="date"
                      label="Data de nascimento"
                    />
                    <Input name="email" label="E-mail" type="email" />
                    <InputMask
                      name="phone"
                      label="Celular"
                      mask="(99) 99999-9999"
                    />
                  </section>
                  <h4>Endereço do sócio {index + 1}</h4>
                  <Scope path="address">
                    <section>
                      <Select
                        name="type"
                        className="address_type"
                        label="Tipo"
                        placeholder="Selecione um tipo"
                        options={addressTypeOptions}
                      />
                      <Input name="id" type="text" className="hide" readOnly />
                      <InputMask
                        name="zipcode"
                        className="zipcode"
                        label="CEP"
                        mask="99999-999"
                        onChange={e =>
                          handleChangeZipcode(e.target.value, index)
                        }
                      />
                      <Input
                        name="street"
                        className="street"
                        label="Endereço"
                      />
                      <Input
                        id={`number${index}`}
                        name="number"
                        className="number"
                        label="Nº"
                      />
                    </section>
                    <section>
                      <Input
                        name="complement"
                        className="complement"
                        label="Complemento"
                      />
                      <Input
                        name="neighborhood"
                        className="neighborhood"
                        label="Bairro"
                      />
                      <Select
                        name="state"
                        className="state"
                        label="Estado"
                        placeholder="Selecione um estado"
                        options={states}
                        onChange={e => handleChangeState(e.value, index)}
                      />
                      {item.address.state && (
                        <Select
                          name="city"
                          className="city"
                          label="Cidade"
                          placeholder="Selecione uma cidade"
                          defaultValue={{
                            value: item.address.city,
                            label: item.address.city,
                          }}
                          options={item.address?.citiesOptions || []}
                        />
                      )}
                    </section>
                  </Scope>
                </Scope>
              );
            })}
          </FormContainer>

          <Options>
            <Button type="button" onClick={() => formRef.current.submitForm()}>
              Próxima etapa
              <FaAngleDoubleRight size={15} />
            </Button>

            {processModel.process_type === 2 && (
              <Button type="button" onClick={handleAddRelated}>
                Adicionar
                <FaPlus size={10} />
              </Button>
            )}
          </Options>
        </>
      )}

      {cepLoading && <Loading />}

      {createProcessLoading && (
        <LoadingMessage message="Estamos buscando seus dados. " />
      )}
    </>
  );
};

export default Form;
