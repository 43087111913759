import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  format,
  parseISO,
  addDays,
  getYear,
  getMonth,
  getDate,
  setMinutes,
  setHours,
  isAfter,
} from 'date-fns';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import * as Yup from 'yup';
import produce from 'immer';
import { v4 as uuid } from 'uuid';
import {
  FaCalendarAlt,
  FaSave,
  FaBroom,
  FaTimes,
  FaMinus,
} from 'react-icons/fa';

import { useAuth } from '~/hooks';

import api from '~/services/api';
import history from '~/services/history';

import {
  FormContainer,
  FormLoading,
  Input,
  InputMask,
  Select,
  DatePicker,
  Checkbox,
  TextArea,
} from '~/components/Form';
import Loading from '~/components/Loading';
import ConfirmWindow from '~/components/ConfirmWindow';

import { SendFeedback } from '~/components/SendFeedback';
import base64ToBlob from '~/util/converterBase64ToBLob';
import {
  Container,
  Header,
  Controls,
  Content,
  AppointmentInfo,
  Feedback,
  FeedbackItem,
} from './styles';

const Form = () => {
  const { user, company, companyUser, companyUsers } = useAuth();

  const { state } = useLocation();

  const id = state?.id || null;

  const formRef = useRef(null);
  const feedbackRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [showMonitorConclusion, setShowMonitorConclusion] = useState(false);
  const [copyFile, setCopyFile] = useState(null);

  const [appointment, setAppointment] = useState(null);
  const [listOfFeedback, setListOfFeedback] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);

  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState(0);

  const [supplierOptions, setSupplierOptions] = useState([]);

  useEffect(() => {
    async function loadAppointment() {
      if (company && companyUser) {
        if (id) {
          try {
            setLoading(true);

            const response = await api.get(`schedule/${id}`);

            if (response.data) {
              const { data } = response;

              const { start_date, deadline_date, conclusion_date } = data;

              data.start_date = parseISO(start_date);
              const deadlineDateObj = new Date(deadline_date);
              data.deadline_hour = deadline_date
                ? `${String(deadlineDateObj.getUTCHours()).padStart(
                    2,
                    '0'
                  )}:${String(deadlineDateObj.getUTCMinutes()).padStart(
                    2,
                    '0'
                  )}`
                : null;
              data.deadline_date = deadline_date
                ? parseISO(deadline_date.split('T')[0])
                : null;
              data.conclusion_date = conclusion_date
                ? parseISO(conclusion_date)
                : null;
              data.start_hour = format(parseISO(start_date), 'HH:mm');
              data.recipient_id = data.recipient.id;

              setAppointment(data);

              if (data.recipient_id !== companyUser.user_id) {
                setShowMonitorConclusion(true);
              }

              if (data.supplier) {
                data.supplier = data.supplier.id;
              }
            }

            setLoading(false);
          } catch (err) {
            toast.error('Falha ao buscar compromisso', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            setLoading(false);
          }
        } else {
          setAppointment({
            type: 0,
            priority: 0,
            private: true,
            monitor: true,
          });
        }
      }
    }

    loadAppointment();
  }, [company, id, companyUser]);

  useEffect(() => {
    async function loadSupplierOptions() {
      if (company) {
        const response = await api.get(`schedule/contacts/${company.id}`);

        const options = response.data.map(supplier => {
          const data = {
            value: supplier.id,
            label: supplier.name,
          };

          return data;
        });

        setSupplierOptions(options);
      }
    }

    loadSupplierOptions();
  }, [company]);

  const usersOptions = useMemo(() => {
    if (user && companyUsers) {
      const options = companyUsers
        .filter(userItem => userItem.user_id !== user.id)
        .filter(userItem => userItem.user_id !== -1)
        .filter(userItem => userItem.active !== false)
        .map(userItem => {
          return { value: userItem.user_id, label: userItem.short_name };
        });

      options.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

      options.unshift({
        value: user.id,
        label: user.short_name,
      });

      return options;
    }

    return [];
  }, [companyUsers, user]);

  const priorityOptions = useMemo(() => {
    const options = [
      { value: 0, label: 'Normal' },
      { value: 1, label: 'Urgente' },
    ];

    return options;
  }, []);

  const typeOptions = useMemo(() => {
    if (appointment) {
      if (appointment.type === 5) return [{ value: 5, label: 'Suporte' }];

      if (appointment.type === 6)
        return [{ value: 6, label: 'Etapa de Processo' }];

      if (appointment.type === 7)
        return [{ value: 7, label: 'Controle de Vencimento' }];

      return [
        { value: 0, label: 'Tarefa' },
        { value: 2, label: 'Conta a pagar' },
        { value: 3, label: 'KPI' },
        { value: 4, label: 'Aviso' },
      ];
    }

    return [
      { value: 0, label: 'Tarefa' },
      { value: 2, label: 'Conta a pagar' },
      { value: 3, label: 'KPI' },
      { value: 4, label: 'Aviso' },
    ];
  }, [appointment]);

  const handleChangeRecipient = useCallback(
    selectedOption => {
      const { value } = selectedOption;

      if (value === companyUser.user_id) {
        setShowMonitorConclusion(false);
      } else {
        setShowMonitorConclusion(true);
      }
    },
    [companyUser]
  );

  const handleChangeType = useCallback(selectedOption => {
    const { value } = selectedOption;

    setSelectedType(value);
  }, []);

  const resetForm = useCallback(() => {
    formRef.current.reset();
  }, [formRef]);

  const confirmResetForm = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmWindow onClick={resetForm} onClose={onClose} />;
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, [resetForm]);

  const handleClose = useCallback(() => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <ConfirmWindow
            onClick={() => history.push('/schedule', { id: null })}
            onClose={onClose}
          />
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  }, []);

  const handleSubmit = useCallback(
    async data => {
      if (company && user) {
        try {
          let blob = null;
          if (copyFile) {
            blob = base64ToBlob(copyFile);
          }
          setSaveLoading(true);

          if (copyFile) {
            data.file = {};
            data.file.name = 'feedback.png';
            data.file.type = 'image/png';
          }

          const schema = Yup.object().shape({
            description: Yup.string().required('A mensagem é obrigatória.'),
            start_date: Yup.string()
              .required('A data de início é obrigatória.')
              .nullable(),
            start_hour: Yup.string().required(
              'A hora de início é obrigatória.'
            ),
            recipient_id: Yup.string().required('O recipiente é obrigatório.'),
            priority: Yup.string().required('A prioridade é obrigatória.'),
            type: Yup.string().required('O tipo é obrigatório.'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          data.done = false;
          data.conclusion_date = data.conclusion_date || null;

          const startHour = data.start_hour.split(':');
          const deadlineHour = data.deadline_hour.split(':');

          data.start_date = setMinutes(
            setHours(
              new Date(
                getYear(data.start_date),
                getMonth(data.start_date),
                getDate(data.start_date)
              ),
              startHour[0]
            ),
            startHour[1]
          );

          if (data.deadline_date) {
            const deadlineDateObj = new Date(
              getYear(data.deadline_date),
              getMonth(data.deadline_date),
              getDate(data.deadline_date)
            );
            if (data.deadline_hour) {
              deadlineDateObj.setUTCHours(deadlineHour[0], deadlineHour[1]);
            } else {
              deadlineDateObj.setUTCHours(23, 59, 0);
            }
            data.deadline_date = deadlineDateObj;
          } else {
            const deadlineDateObj = new Date();
            deadlineDateObj.setDate(deadlineDateObj.getDate() + 2);
            deadlineDateObj.setUTCHours(23, 59, 0);
            data.deadline_date = deadlineDateObj;
          }

          if (isAfter(data.start_date, data.deadline_date)) {
            data.deadline_date = addDays(data.start_date, 7);
          }

          if (id) {
            await api.put(`schedule/${id}`, data);
          } else {
            data.company_id = company.id;
            const createdSchedule = await api.post(`schedule`, data);

            const { id: createdId } = createdSchedule.data;

            if (listOfFeedback.length > 0) {
              const promises = listOfFeedback.map(async feedback => {
                const feedbackData = {
                  company_id: company.id,
                  schedule_id: createdId,
                  user_id: user.id,
                  content: feedback.content,
                  type: feedback.type,
                  file_name: feedback.file_name,
                };

                if (feedback.type === 1) {
                  const formData = new FormData();

                  if (!copyFile) {
                    formData.append('file', feedback.file);
                  } else {
                    formData.append('file', blob, 'feedback.png');
                    setCopyFile(null);
                  }

                  const fileResponse = await api.post(
                    'files/upload',
                    formData,
                    {
                      params: {
                        prefix: 'Schedule_Feedback',
                      },
                    }
                  );

                  const { blobName } = fileResponse.data;

                  feedbackData.link = blobName;

                  await api.post('schedule/feedback', feedbackData, {
                    params: {
                      isCreatingNewSchedule: true,
                    },
                  });
                } else {
                  await api.post('schedule/feedback', feedbackData, {
                    params: {
                      isCreatingNewSchedule: true,
                    },
                  });
                }
              });

              await Promise.all(promises);
            }
          }

          formRef.current.setErrors({});

          toast.success('Compromisso salvo com sucesso.', {
            position: toast.POSITION.BOTTOM_RIGHT,
          });

          setSaveLoading(false);

          history.push('/schedule', { id: null });
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            formRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar compromisso.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setSaveLoading(false);
        }
      }
    },
    [id, company, listOfFeedback, user, copyFile]
  );

  const handleFeedback = useCallback(
    async data => {
      if (user) {
        try {
          const schema = Yup.object().shape({
            feedback_message: Yup.string().when('file', {
              is: value => value !== undefined,
              then: Yup.string(),
              otherwise: Yup.string().required('A mensagem é obrigatória'),
            }),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          setListOfFeedback(
            produce(listOfFeedback, draft => {
              const newFeedback = {
                id: uuid(),
                feedback_date_formatted: format(
                  new Date(),
                  "dd/MM/yyyy 'às' HH:mm"
                ),
                short_name: user.short_name,
                type: data.file ? 1 : 0,
                file: data.file || null,
                content:
                  data.file && data.feedback_message === ''
                    ? data.file.name
                    : data.feedback_message,
                file_name: data.file ? data.file.name : null,
              };

              if (listOfFeedback.length > 0) {
                draft.unshift(newFeedback);
              } else {
                draft[0] = newFeedback;
              }
            })
          );

          setUploadFile([]);

          feedbackRef.current.reset();

          feedbackRef.current.setErrors({});
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            const errorMessages = {};

            err.inner.forEach(error => {
              errorMessages[error.path] = error.message;
            });

            feedbackRef.current.setErrors(errorMessages);
          } else {
            toast.error('Falha ao salvar feedback.', {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          setUploadFile([]);
        }
      }
    },
    [listOfFeedback, user]
  );

  useEffect(() => {
    if (appointment && !id) {
      const element = document.getElementById('feedback_message');

      if (element) {
        element.addEventListener('keydown', event => {
          if (event.ctrlKey && event.key === 'Enter') {
            handleFeedback({ feedback_message: event.target.value });
          }
        });
      }
    }
  }, [appointment, id, handleFeedback]);

  const handleRemoveFeedback = useCallback(
    index => {
      setListOfFeedback(
        produce(listOfFeedback, draft => {
          delete draft[index];
        })
      );
    },
    [listOfFeedback]
  );

  return (
    <>
      <Container>
        <Header>
          <div>
            <FaCalendarAlt size={20} color="#44546a" />
            <h1>Agenda</h1>
          </div>
        </Header>

        <Controls>
          <button type="button" onClick={() => formRef.current.submitForm()}>
            <FaSave size={15} color="#44546a" />
            <span>Salvar</span>
          </button>
          <button type="button" onClick={confirmResetForm}>
            <FaBroom size={15} color="#44546a" />
            <span>Limpar</span>
          </button>
          <button type="button" onClick={handleClose}>
            <FaTimes size={15} color="#44546a" />
            <span>Fechar</span>
          </button>
        </Controls>
        {(loading || !user || !company || !companyUser || !companyUsers) && (
          <FormLoading className="loading" />
        )}
        {appointment && (
          <Content className="content">
            <FormContainer
              ref={formRef}
              initialData={appointment}
              onSubmit={handleSubmit}
              loading={loading ? 1 : 0}
            >
              <AppointmentInfo>
                <h4>AGENDA</h4>
                <section>
                  {id ? (
                    // <DatePicker
                    //   name="start_date"
                    //   className="start_date"
                    //   label="Data de início"
                    // />
                    <></>
                  ) : (
                    <DatePicker
                      name="start_date"
                      className="start_date"
                      label="Data de início"
                      selected={selectedStartDate}
                      onChange={date => setSelectedStartDate(date)}
                    />
                  )}
                  {id ? (
                    <InputMask
                      name="start_hour"
                      className="start_hour"
                      type="text"
                      label="Hora"
                      mask="99:99"
                    />
                  ) : (
                    <InputMask
                      name="start_hour"
                      className="start_hour"
                      type="text"
                      label="Hora"
                      mask="99:99"
                      defaultValue="07:00"
                    />
                  )}
                  {id ? (
                    // <DatePicker
                    //   name="start_date"
                    //   className="start_date"
                    //   label="Data de início"
                    // />
                    <></>
                  ) : (
                    <DatePicker
                      name="deadline_date"
                      className="deadline_date"
                      label="Prazo"
                    />
                  )}
                  <InputMask
                    name="deadline_hour"
                    className="deadline_hour"
                    label="Hora"
                    type="text"
                    mask="99:99"
                    // defaultValue="23:59"
                  />
                  <Select
                    name="recipient_id"
                    className="recipient"
                    label="Na agenda de"
                    type="text"
                    options={usersOptions}
                    onChange={handleChangeRecipient}
                    placeholder="Selecione um usuário"
                  />
                  <Select
                    name="priority"
                    className="priority"
                    label="Prioridade"
                    options={priorityOptions}
                    placeholder="Selecione uma prioridade"
                  />
                </section>
                <section>
                  <TextArea
                    name="description"
                    label="Mensagem"
                    className="description"
                  />
                </section>
                <section>
                  <Select
                    name="type"
                    className="type"
                    label="Tipo"
                    options={typeOptions}
                    onChange={handleChangeType}
                    placeholder="Selecione um tipo"
                  />
                  <Checkbox
                    id="private"
                    name="private"
                    className="private"
                    label="Privado"
                  />
                  {showMonitorConclusion && (
                    <Checkbox
                      id="monitor"
                      name="monitor"
                      className="monitor"
                      label="Monitorar conclusão"
                    />
                  )}
                  {selectedType === 2 && (
                    <>
                      <Select
                        name="supplier_id"
                        className="supplier"
                        label="Fornecedor"
                        options={supplierOptions}
                        placeholder="Selecione um fornecedor"
                      />
                      <Input
                        name="price"
                        className="price"
                        label="Valor"
                        type="text"
                      />
                    </>
                  )}
                </section>
              </AppointmentInfo>
            </FormContainer>
            {!id && (
              <Feedback ref={feedbackRef} onSubmit={handleFeedback}>
                <h4>FEEDBACK</h4>
                <SendFeedback
                  uploadFile={uploadFile}
                  feedbackRef={feedbackRef}
                  copyFile={copyFile}
                  setCopyFile={setCopyFile}
                  setUploadFile={setUploadFile}
                />
                {listOfFeedback.length > 0 &&
                  listOfFeedback.map((feedback, index) => (
                    <FeedbackItem key={`${feedback.id}${index}`}>
                      <button
                        type="button"
                        onClick={() => handleRemoveFeedback(index)}
                      >
                        <FaMinus size={10} />
                      </button>

                      <div className="date">
                        <label>Data/Hora</label>
                        <input
                          name="feedback_date_formatted"
                          value={feedback.feedback_date_formatted}
                          readOnly
                        />
                      </div>
                      <div className="user">
                        <label>Usuário</label>
                        <input
                          name="user"
                          value={feedback.short_name}
                          readOnly
                        />
                      </div>
                      {feedback.type === 0 ? (
                        <div className="content">
                          <label>Mensagem</label>
                          <textarea
                            name="content"
                            value={feedback.content}
                            readOnly
                          />
                        </div>
                      ) : (
                        <>
                          <div className="content">
                            <label>Mensagem</label>
                            <input
                              name="content"
                              value={feedback.content}
                              readOnly
                            />
                          </div>
                          <div className="file">
                            <label>Arquivo</label>
                            <input
                              type="text"
                              value={feedback.file_name}
                              readOnly
                            />
                          </div>
                        </>
                      )}
                    </FeedbackItem>
                  ))}
              </Feedback>
            )}
          </Content>
        )}
      </Container>

      {saveLoading && <Loading />}
    </>
  );
};

export default Form;
