import styled from 'styled-components';
import { Form } from '@unform/web';

export const Filter = styled(Form)`
  padding: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border};

  display: flex;
  align-items: center;
  justify-content: space-between;

  .name,
  .situation,
  .client {
    width: 33%;
  }

  > div + div {
    margin-left: 16px;
  }

  button {
    border: 0;
    background: transparent;
    margin: 0 16px;
    transition: opacity 0.2s;
    width: 4%;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    svg {
      color: ${({ theme }) => theme.colors.primary};
      height: 20px;
      width: 20px;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;

  .table {
    .cpf {
      padding-left: 8px;
      width: 20%;
    }

    .name {
      width: 35%;
    }

    .client {
      width: 35%;
    }

    .situation {
      padding-left: 8px;
      width: 10%;
    }
  }
`;

export const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: column;

  .cpf,
  .situation,
  .date {
    width: 20%;
  }

  .name,
  .client {
    width: 30%;
  }
`;
