import React, { useEffect, useState } from 'react';
import { FaLink, FaCopy, FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import base64 from 'base-64';
import copy from 'copy-to-clipboard';
import Modal from '~/components/Modal';
import { TableContainer, TableLoading } from '~/components/Table';
import api from '~/services/api';
import { useAuth } from '~/hooks';
import { Container, Header, Content } from './styles';

const ProcessesLinks = ({ isOpen, setIsOpen }) => {
  const [processModels, setProcessModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const { company } = useAuth();

  useEffect(() => {
    if (!company) return;

    (async () => {
      try {
        const response = await api.get(`/process-models/simple`, {
          params: {
            company_id: company.id,
            selectOnly: true,
          },
        });

        setProcessModels(response.data);
      } catch {
        toast.error('Falha ao buscar modelos.', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [company]);

  const copyLink = processModelId => {
    const link = `https://app.diretiva1.com.br/redirect/${base64.encode(
      company.id
    )}/${base64.encode(processModelId)}`;

    copy(link);

    toast.success('Link copiado.', {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <Header>
          <div>
            <FaLink size={20} color="#44546a" />
            <h1>Links Processos</h1>
          </div>
          <aside>
            <button type="button" onClick={setIsOpen}>
              <FaTimes size={20} color="#44546a" />
            </button>
          </aside>
        </Header>
        <Content>
          {loading ? (
            <TableLoading />
          ) : (
            <TableContainer>
              <thead>
                <tr>
                  <th className="copy" />
                  <th className="name">Nome processo</th>
                </tr>
              </thead>
              <tbody>
                {processModels.map(processModel => (
                  <tr>
                    <td className="copy">
                      <button
                        type="button"
                        onClick={() => copyLink(processModel.id)}
                      >
                        <FaCopy color="#44546a" />
                      </button>
                    </td>
                    <td className="name">{processModel.title}</td>
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          )}
        </Content>
      </Container>
    </Modal>
  );
};

export default ProcessesLinks;
